.info-module-delivery-info {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 120px;

    &__head {
        grid-area: title;
        padding: 24px 0;
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
    }

    &__product-table {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }

    &__invoice-info {
        display: grid;
        grid-template-areas:
            "number date"
            "supplier date";
        align-items: end;
        gap: 6px;
        color: var(--color-black);
        padding: 12px 10px;
        margin-bottom: 10px;
        background-color: var(--color-white-md);

        &--red {
            color: var(--color-redish);
            background-color: var(--color-light-red);
        }
    }

    &__invoice-number {
        font-weight: 500;
        font-size: 16px;
        line-height: 18.7px;
    }

    &__invoice-date {
        grid-area: date;
        justify-self: end;
    }

    &__container {
        display: grid;
        grid-template-areas: 
        'title btn'
        'container container';
        align-items: center;
        justify-items: start;
        margin-bottom: 20px;

        @media(--breakpoint-tablet-up) {
            grid-template-areas: 
            'title title'
            'container btn';
        }
    } 

    &__btn-container {
        display: flex;
        flex-direction: column;
        align-self: center;
        gap: 8px;
        width: 100%;
        grid-area: container;

        @media (--breakpoint-tablet-up) {
            display: grid;
            grid-auto-columns: minmax(0, 1fr);
            grid-auto-flow: column;
            align-self: flex-start;
            width: auto;
        }
    }

    &__filters {
        margin-bottom: 14px;
    }

    &__filters-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 18.7px;

        span {
            font-weight: 400;
        }
    }

    &__bottom-btn {
        align-self: flex-start;
    }
}

.info-module-delivery-info .product-chunk {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;

    @media (--breakpoint-tablet-up) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    @media (--breakpoint-desktop-up) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.info-module-delivery-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--color-neutral-mid);

    &__info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px;
    }

    &__name {
        max-width: 495px;
        word-wrap: break-word;
        color: var(--color-black);
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        @media (--breakpoint-desktop-up) {
            max-width: 350px;
        }
    }

    &__identifier {
        word-wrap: break-word;
    }

    &__identifier-number {
        color: var(--color-black);
    }

    &__quantity-table {
        border-top: 1px solid var(--color-neutral-mid);
    }

    &__table-row {
        display: grid;
        grid-template-columns: repeat(4, 25%);
    }

    &__table-row:not(:last-child) {
        border-bottom: 1px solid var(--color-neutral-mid);
    }

    &__table-item {
        display: flex;
        justify-content: center;
        padding: 6px 0;
        color: var(--color-black);
        border-bottom: 0;
        border-right: 0;

        &--invoice-count-higher-than-inventorization-count {
            color: var(--color-category-1);
            background-color: var(--color-product-light-red);
        }

        &--inventorization-count-higher-than-invoice-count {
            color: var(--color-product-green);
            background-color: var(--color-product-light-green);
        }
    }
}
