.info-module-import-form {
    --light-red: #FFF0E5;
    --dark-red: #E4843E;

    width: 100%;

    &__form-field-container {
        position: relative;
        display: flex;
        margin-bottom: 5px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media(--breakpoint-tablet-up) {
            display: grid;
            grid-template-areas: 
            "supplier invoiceNumber date"
            "uploadContainer uploadContainer uploadContainer"
            "btns btns btns"
            ;
            grid-template-columns: repeat(3, 1fr);
            justify-items: center;
        }
    }

    &__regular-field {
        width: 100%;

        @media(--breakpoint-tablet-up) {
            width: auto;
            max-width: 422px;
        }
    }

    &__upload-field {
        min-width: 100%;
        margin-bottom: 40px;

        &--hide {
            display: none;
        }
    }

    &__img-container {
        display: flex;
    }

    &__field-btn {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: 15px;
        background: transparent;
        border: 0;
        cursor: pointer;
        pointer-events: none;
    }

    &__calendar {
        position: absolute;
        right: 0;
        top: 50%;
        padding-right: 13px;
        pointer-events: none;
        transform: translate(0, -50%);
    }

    &__date-label {
        pointer-events: none;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 44px;
        padding: 11px;
        background-color: var(--color-white);
        border: 1px solid #e6e6e6;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }

    &__upload-input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    &__upload-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        border: 1px dashed var(--color-neutral-mid);
    }

    &__upload-text {
        padding: 6px 0 10px 0;
    }

    &__loader-container {
        display: none;
    }

    &__btn-wrapper {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        width: 100%;
        grid-area: btns;

        &--fixed {
            position: fixed;
            bottom: 65px;
            left: 0;
            padding: 20px;
            background-color: var(--color-white);

            @media(--breakpoint-tablet-up) {
                bottom: 0;
            }
        }
    }

    &__btn-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        width: 100%;
        gap: 10px;

        @media(--breakpoint-tablet-up) {
            max-width: 412px;
        }
    }

    &__field-head {
        margin-bottom: 24px;
        font-family: var(--font-primary);
        font-weight: 700;
        font-size: 18px;
    }

    &__upload-content-container {
        grid-area: uploadContainer;
        width: 100%;
    }

    &__error-message {
        visibility: hidden;

        &--show {
            visibility: visible;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 10px;
            margin: 10px 0;
            background-color: var(--color-product-light-red);
            color: var(--color-category-1);
        }
    }

    &__error-message {
        display: none;

        &--show {
            display: flex;
            justify-content: center;
            padding: 10px;
            margin-bottom: 20px;
            color: var(--dark-red);
            background-color: var(--light-red);
        }
    }
}

.info-module-import-form__field-btn--rotate svg {
    transform: rotate(180deg);
}

.info-module-import-form .field1 {
    grid-area: supplier;
}

.info-module-import-form .field2 {
    grid-area: invoiceNumber;
}

.info-module-import-form .field3 {
    grid-area: date;
}

.info-module-import-form--file-uploaded .info-module-import-form__upload-field {
    display: none;
}

.info-module-import-form--file-uploaded .info-module-import-form__loader-container {
    display: block;
}

.form-row.info-module-import-form__regular-field {
    width: 100%;
}

