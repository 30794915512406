.info-module-revision-filtration {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 140px;
    }

    &__head {
        padding: 24px 10px;
        color: var(--color-black);
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;

        @media(--breakpoint-tablet-up) {
            font-size: 30px;
            line-height: 30px;
            margin-bottom: 92px;
        }
    }

    &__btn-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        @media(--breakpoint-tablet-sm-up) {
            width: auto;
            flex-direction: row;
        }
    }

    &__scan-form {
        display: flex;
        flex-direction: column;
    }

    .info-module-revision-filtration__btn {
        min-width: 340px;
    }
}
