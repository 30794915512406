.info-module-revision-list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .btn.btn-secondary {
        box-shadow: none;
    }

    @media(--breakpoint-tablet-sm-up) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: unset;
    }

    @media(--breakpoint-desktop-up) {
        grid-template-columns: repeat(3, 1fr);
    }
}

@supports(height: 100svh) {
    .info-module-revision-list {
        margin-bottom: 120px;
    }
}