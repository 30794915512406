.classification-container {
    display: flex;
    justify-content: space-between;
}

.classification-field {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 7px;
    border-radius: 3px;
    width: fit-content;
    font-size: 12px;

    &--awaiting {
        background-color: var(--color-very-light-green);
        color: var(--color-bright-green);
    }

    &--partial {
        background-color: var(--color-light-red);
        color: var(--color-redish);
    }

    &--delivered {
        background-color: var(--color-light-blue);
        color: var(--color-dark-blue);
    }

    &--differences {
        background-color: var(--color-product-light-red);
        color: var(--color-category-1);
    }

    &--finished {
        color: var(--color-dark-blue);
        background-color: var(--color-light-blue);
    }

    &--in_progress {
        color: var(--color-product-green);
        background-color: var(--color-very-light-green);
    }

    &--type {
        color: var(--color-white);
        background-color: var(--color-primary);
        border: 2px solid var(--color-primary);
    }

    &--assigned {
        color: var(--color-bright-yellow);
        background-color: var(--color-orange);
    }
}