.btn.btn-primary.info-module-import-form__submit-btn {
    min-width: auto;
}

.btn.btn-primary.info-module-import-form__submit-btn:is(:disabled) {
    background: var(--color-text);
}

.info-module-action-btn {
    width: 100%;

    &--space {
        margin-bottom: 20px;
    }

    @media (--breakpoint-desktop-up) {
        width: 317px;
    }
}

.btn.info-module-btn-scroll-top {
    position: sticky;
    display: block;
    right: 0;
    bottom: 10px;
    margin-left: auto;

    @media (--breakpoint-tablet-sm) {
        bottom: 80px;
    }
}

.im-filter-modal-trigger {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    gap: 6px;
    padding: 13px 24px;
    background: var(--color-white);
    border: 1px solid var(--color-light-green);
    border-radius: 3px;
    font-size: 12px;
    line-height: 1em;
    font-weight: 700;
    font-family: var(--font-secondary);
    cursor: pointer;

    svg {
        margin-bottom: 5px;
    }
}