.main.info-module-main-gray {
    padding: 0;
    background-color: var(--color-white-md);
}

#info-module.inventorization-page {
    height: 100vh;
    min-height: unset;
    overflow: hidden;
}

/* safari engine */
@supports (height: 100svh) {
    #info-module.inventorization-page {
        height: 100svh;
    }
}

#info-module.inventorization-page  #js-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: unset;
    overflow: auto;
}

#info-module #js-container .main {
    flex-grow: 1;
}

#info-module .overlay-global.overlay-info-module {
    position: fixed;
    pointer-events: all;
}

.main-body--grow, .main-content--sizing, .info-module-wrapper--gray, .info-module-wrapper--gray pad {
    height: 100%;
}
