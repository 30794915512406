.product-inventorization {
    max-height: calc(100svh - 91px);
    height: 100%;
    overflow: auto;
    background-color: var(--color-white-md);

    &__form {
        height: 100%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;

        @media(--breakpoint-tablet-up) {
            display: grid;
            grid-template-areas:
                'info info calculator'
                'info info none'
                'btns btns none';
            gap: 30px;
        }
    }

    &__info-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 12px;
        flex: 1;
        padding: 8px;
        grid-area: info;
        border-radius: 6px;
        background-color: var(--color-white);

        @media(--breakpoint-tablet-up) {
            padding: 15px;
        }
    }

    &__product-info {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    &__product-name {
        font-family: var(--font-secondary);
        color: var(--color-black);
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;

        @media(--breakpoint-mobile-up) {
            font-size: 16px;
            line-height: 21px;
        }

        @media(--breakpoint-tablet-up) {
            max-width: 85%;
        }
    }

    &__product-number {
        font-family: var(--font-primary);
        color: var(--color-black);
        font-weight: 400;
        font-size: 14px;
        line-height: 16.4px;
    }

    &__see-more-btn {
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
    }

    &__calculation-field {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        min-height: 20px;
        align-self: center;
        padding: 3px 15px;
        border-radius: 3px;
        color: var(--color-black);
        background-color: var(--color-white-md);

        &--hidden {
            background-color: var(--color-white);
        }
    }

    &__end-result-field {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        min-width: 156px;
        min-height: 40px;
        border: 1px solid var(--color-loght-gray);
        align-self: center;

        &--barcode-only {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media(--breakpoint-tablet-up) {
            min-height: 50px;
        }
    }

    &__calculation-field-btn {
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &--hide {
            display: none;
        }
    }

    &__input-field {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__unit-input {
        display: none;
    }

    &__unit-input:checked+&__unit-select-option {
        background-color: var(--color-light-green);
        color: var(--color-white);
    }

    &__unit-select {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-self: center;
        padding: 2px;
        background-color: var(--color-white-md);
        border: 0;
        border-radius: 3px;
    }

    &__unit-single-option {
        align-self: center;
        padding: 8px 10px;
        max-width: 55px;
        border: 0;
        border-radius: 3px;
        background-color: var(--color-light-green);
        color: var(--color-white);
    }

    &__unit-select-option {
        display: flex;
        justify-content: center;
        padding: 8px 10px;
        border-radius: 3px;
        color: var(--color-black);
        font-weight: 500;
        font-size: 14px;
        line-height: 16.4px;
    }

    &__fields-container {
        display: flex;
        flex-direction: column;
        gap: 5px;

        @media(--breakpoint-tablet-up) {
            margin-bottom: 90px;
        }
    }

    &__quantity-field {
        border-radius: 3px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16.75px;

        @media(--breakpoint-mobile-up) {
            font-size: 16px;
            line-height: 18.75px;
        }
    }

    &__quantity-field-number {
        font-weight: 700;
    }

    &__available-quantity {
        color: var(--color-dark-blue);

        &--hide {
            display: none;
        }
    }

    &__inventorized-quantity {
        color: var(--color-purple);
    }

    &__excess-amount {
        display: none;
        color: var(--color-redish);

        &--show {
            display: block;
        }
    }

    &__btn-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        padding-bottom: 10px;
        width: auto;
        grid-area: btns;

        @media(--breakpoint-tablet-up) {
            max-height: 55px;
            max-width: 600px;
            width: 100%;
            gap: 16px;
        }
    }

    &__submit-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: flex-start;
        width: 100%;
        padding: 9px 5px;
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 12px;
        border-radius: 3px;
        border: 0;
        cursor: pointer;

        &[disabled] {
            background-color: var(--color-text);
        }

        @media(--breakpoint-mobile-up) {
            font-size: 14px;
            padding: 15px 5px;
        }
    }

    &__save-btn {
        background-color: var(--color-light-green);
        color: var(--color-white);
    }

    &__add-quantity-btn {
        background-color: var(--color-neutral-mid);
        color: var(--color-black);
    }

    &__calculation-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        gap: 10px;

    }

    &__calculation-fields-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    &__deduct-quantity-btn {
        display: none;
    }

    &--hide {
        display: none;
    }
}

.product-inventorization .phantom-div {
    display: none;
    height: 285px;

    @media(--breakpoint-tablet-up) {
        display: none;
    }
}

.product-inventorization .operator {
    padding: 0 5px;
}

.product-inventorization--scan-only {
    .info-module-calculator {
        display: none;
    }

    .product-inventorization__container {
        display: flex;
        flex-direction: column;
    }

    .product-inventorization__calculation-field-btn {
        display: none;
    }

    .product-inventorization__end-result-field {
        display: flex;
        justify-content: center;
    }

    .product-inventorization__deduct-quantity-btn {
        display: flex;
    }

    .product-inventorization__save-btn {
        display: none;
    }

    .product-inventorization__add-quantity-btn {
        background-color: var(--color-primary);
        color: var(--color-white);
    }
    

    .product-inventorization__deduct-quantity-btn {
        background-color: var(--error);
        color: var(--color-white);
    }
}