/* ------------------------------------------------------------ *\
	#footer
\* ------------------------------------------------------------ */
.footer { overflow: hidden; width: 100%; font-size: 13px; line-height: 1.3; background: #E3E3E3; color: #939393; }
.footer-top { position: relative; padding: 25px 15px; }
.footer-left { float: left; width: 780px; padding: 30px 0; }
.footer-right { float: left; width: 520px; }
.footer-top:before { content: ''; position: absolute; top: 0; bottom: 0; left: 0; z-index: -1; width: 2000px; background: inherit; }
.footer-top .cols { margin-bottom: 0; }

.footer-title { margin-bottom: 20px; font-family: var(--font-secondary); font-size: 15px; line-height: 1.3; color: #000; text-transform: uppercase; font-weight: normal; }
.footer .list-links { list-style: none; padding-left: 0; }
.footer .list-links li { padding-bottom: 2px; }
.footer .list-links a { font-size: 15px; line-height: 20px; color: inherit; font-weight: 700; }
.footer .list-links a:hover { padding-left: 5px; color: var(--color-primary); text-decoration: none; }
.footer .list-links button { font-size: 15px; line-height: 20px; color: inherit; font-weight: 700; border: 0; background-color: transparent;}
.footer .list-links button:hover { padding-left: 5px; color: var(--color-primary); text-decoration: none; }
.footer .socials-footer { margin-right: 30px; }

.footer-contacts { border-left: 1px solid #b4b4b4; padding: 0 0 0 45px; }
.footer-contacts { padding-bottom: 10px; font-size: 14px; margin-right: 30px; }
.footer-contacts > a { display: block; font-size: 30px; line-height: 1.2; color: var(--color-primary); font-weight: 500; }
.footer-contacts > a:hover { text-decoration: none; }

.footer-bottom { position: relative; border-top: 1px solid #ffffff; }
.footer-hotlinks { list-style: none; padding: 0 15px; margin: 0 0 40px; font-size: 0; line-height: 0; }
.footer-hotlink { display: inline-block; margin-right: 10px; }
.footer-hotlink a { display: block; padding: 15px 0; font-size: 16px; text-decoration: underline; line-height: 1.2; color: #797979; font-weight: 700; }
.copyrights { padding: 15px;  }
.footer-bottom .copy { float: left; line-height: 24px; }
.footer-bottom .trademark { float: right; }
.footer-bottom .trademark > * { display: inline-block; vertical-align: middle; }
.footer-bottom .trademark .icon-svg { display: inline-block; margin-left: 10px; height: 24px; width: 80px; fill: #4b9cd7; }


.socials-footer { margin: 0 -10px; }
.socials-footer .socials-item { margin: 10px; display: block; }
.socials-footer .socials-item:before { content: ''; position: absolute; top: 1px; left: 1px; z-index: -1; width: 22px; height: 22px; border-radius: 50%; background: #fff; transition: all 0.3s; }
.socials-footer .socials-item:hover .icon-svg { fill: #fff; }
.socials-footer .socials-item span { display: inline-block; vertical-align: top; padding: 0 5px; font-size: 14px; line-height: 24px; font-weight: bold; }
.socials-footer .socials-item:hover { color: #fff; }
.socials-footer .socials-item:hover:before { opacity: 0; visibility: hidden; }