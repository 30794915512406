.info-module-header {
    position: relative;
    padding: 10px 0;
    width:100%;
    background: var(--color-white);
    border-bottom: 1px solid var(--color-neutral-mid);
    transition: z-index 0.3s;
    z-index: 102;

    &__container {
        display: grid;
        grid-template-columns: 3fr 6fr 3fr;

        @media (--breakpoint-tablet-up) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__dropdown-btn {
        justify-self: left;
        background-color: transparent;
        border:0;

        @media(--breakpoint-tablet-up) {
            display: none;
        }
    }

    &__logo-container {
        justify-self: center;
        height: fit-content;

        @media (--breakpoint-tablet-up) {
            justify-self: auto;
        }
    }

    &__logo {
        width: 158px;
        height: 37px;

        @media(--breakpoint-tablet-up) {
            width: 204px;
            height: 50px;
        }
    }

    &__btn-container {
        display: flex;
        justify-content: flex-end;
        gap:40px;
    }

    &__navigation-container {
        @media (--breakpoint-tablet-up) {
            display:flex;
            align-items: center;
            padding-right: 40px;
            border-right: 1px solid var(--color-neutral-mid);
        }
    }

    &__logout-btn-text {
        display: none;

        @media (--breakpoint-tablet-sm-up) {
            display: block;
        }
    }

    &--hide {
        transition: z-index 0.3s;
        z-index: 1;
    }

    &--remove {
        display: none;

        @media(--breakpoint-tablet-sm-up) {
            display: block;
        }
    }
}

.info-module-header--no-mobile-nav .info-module-header__navigation-container {
    display: none;

    @media(--breakpoint-tablet-up) {
        display: flex;
    }
}


.info-module-mobile-menu {
    top: 100%;
}