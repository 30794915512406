.info-module-revision {
    --color-border: #DFDFDF;

    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 12px;
    border: 1px solid var(--color-border);

    &__info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__info-group {
        display: flex;
        flex-direction: column;
    }

    &__label {
        font-size: 12px;
    }

    &__date {
        display: flex;
        gap: 6px;
        color: var(--color-black);
    }

    &__btn-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 8px;
        height: 100%;
    }
}