.info-module-search-results {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-height: 60vh;
    overflow: auto;

    &__list {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}