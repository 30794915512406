.im-revision-filters {
    position: relative;

    &__container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-height: 100%;

        @media(--breakpoint-tablet-sm-up) {
            max-height: calc(100% - 104px);
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__head-container {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid var(--color-neutral-mid);
    }

    &__head {
        color: var(--color-black);
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }

    &__close-btn {
        border: 0;
        background-color: transparent;
        cursor: pointer;
    }

    &__filter-container {
        overflow: auto;
        padding: 0 12px;

        @media (--breakpoint-tablet-sm-up) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
        }
    }

    &__submit-btn {
        padding: 30px 12px;
        margin: 0 auto;
    }

    @media (--breakpoint-tablet-sm-up) {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.info-module-filter-modal {
    max-height: 100vh;

    @media (--breakpoint-tablet-sm-up) {
        max-height: 519px;
    }
}

@supports (height: 100svh) {
    .info-module-filter-modal {
        max-height: 100svh;

        @media (--breakpoint-tablet-sm-up) {
            max-height: 519px;
        }
    }
}

@supports (height: 100dvh) {
    .info-module-filter-modal {
        max-height: 100dvh;

        @media (--breakpoint-tablet-sm-up) {
            max-height: 519px;
        }
    }
}
