.lazy-loading-loader {
    align-self: center;
    border: 8px solid var(--color-neutral-mid);
    border-top: 8px solid var(--color-primary);
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    animation: spin 2s linear infinite;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &--overlay {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.5);
        z-index: 102;
    }

    &--window-cover {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.5);
        z-index: 102;
    }

    &--hide {
        display: none;
    }
}

.info-module-loader {
    border: 10px solid var(--color-white-md);
    border-top: 10px solid var(--color-light-green);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.navbar_lazy_loading {
    position: relative;
    top: 20px;
}