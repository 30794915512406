.info-module-upload-loader {
    display: flex;
    flex-direction: column;
    margin: 10px 0 100px 0;
    gap: 10px;

    &__info {
        display: flex;
        justify-content: space-between;
    }

    &__first-half {
        display: flex;
        gap: 15px;
    }

    &__second-half {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    &__close-btn {
        background-color: transparent;
        border: 0;
        cursor: pointer;
    }


    &__bar {
        position: relative;
        width: 100%;
        height: 6px;
        background-color: var(--color-white-md);
        grid-area: loader;
    }

    &__progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: var(--color-light-green);
        z-index: 1;
    }

    &__file-name {
        color: var(--color-black);
    }

    &__success-indicator {
        display: none;
    }
}

.info-module-upload-loader--load-finished .info-module-upload-loader__percentage {
    display: none;
}

.info-module-upload-loader--load-finished .info-module-upload-loader__second-half {
    flex-direction: row;
    gap: 16px;
}

.info-module-upload-loader--load-finished .info-module-upload-loader__bar {
    display: none;
}

.info-module-upload-loader--load-finished .info-module-upload-loader__success-indicator {
    display: flex;
    align-items: center;
    gap: 3px;
    color: var(--color-light-green);
}



