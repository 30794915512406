@import './_navigation-bar.css';
@import './_search-options';
@import './_subhead';
@import './_header';
@import './_invoices.css';
@import './_side-bar.css';
@import './_page-title.css';
@import './_import-invoice.css';
@import './_form-import-invoice.css';
@import './_btns.css';
@import './_loader.css';
@import './_product.css';
@import './_product-list.css';
@import './_invoice-details.css';
@import './_deliveries-list.css';
@import './_delivery.css';
@import './_filter-container.css';
@import './_search-results.css';
@import './_circular-loader.css';
@import './_product-inventorization.css';
@import './_calculator.css';
@import './_main.css';
@import './_inventorization-response.css';
@import './_aside';
@import './_revision.css';
@import './_revision-list.css';
@import './_product-info.css';
@import './_revision-filters.css';
@import './_revision-filtration.css';
@import './_revision-categorie-filter.css';
@import './_custom-dropdown.css';
@import './_classification-field.css';
@import './_filter-modal.css';
@import './_low-availibiity-page.css';
@import './_confirm-modal.css';


#info-module {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
    }

    #js-form-filter {
        background-color: var(--color-white);
    }
}

.info-module-text-head {
    padding: 24px 0 20px 0;
    font-weight: 700;
    font-size: 24px;
}

.no-scroll {
    overflow: hidden;
}

.bottom-padding {
    padding-bottom: 60px;

    @media(--breakpoint-desktop-up) {
        padding-bottom: unset;
    }
}

#info-module .hidden-input {
    display: none;
}

.disable-anchor {
    pointer-events: none;
}
