.invoices {
    background-color: var(--color-white);

    &__container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 55px;
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64px;
        color: var(--color-white);
        background-color: var(--color-secondary);
        border-radius: 5px;
        font-family: var(--font-secondary);
        font-size: 24px;
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        gap: 20px;

        @media(--breakpoint-tablet-sm-up) {
            grid-template-columns: 1fr 1fr;
        }

        @media (--breakpoint-tablet-up) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    &__import-btn {
        width: 100%;

        @media(--breakpoint-desktop-up) {
            width: 317px;
        }
    }

    &__btn-img {
        padding-bottom: 5px;
        vertical-align: text-bottom;
        margin-right: 5px;
    }
}

.invoices .invoices__import-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.invoice {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    padding: 12px;
    border: 1px solid var(--color-light-beige);
    border-radius: 3px;

    &__additional-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 6px;
    }

    &__info {
        color: var(--color-black);
    }

    &__head {
        width: 100%;
        word-wrap: break-word;
        line-break: anywhere;
        font-weight: 500;
        color: var(--color-black);
    }

    &__btns-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__info-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__supplier {
        min-width: 120px;
        max-width: 300px;
        word-wrap: break-word;
        line-break: anywhere;
    }

    @media(--breakpoint-mobile-up) {
        width: 65%;
    }

    @media(--breakpoint-tablet-sm-up) {
        max-width: 340px;
        width: 100%;
    }
}

.invoice .btn.btn-secondary {
    box-shadow: none;
}
