.info-module-input-filter {
    position: relative;
    min-height: 40px;
    border: 1px solid var(--color-neutral-mid);

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__btn {
        height: 100%;
        min-width: 45px;
        border: 0;
        padding: 5px;
        background-color: #a6ce39;
    }

    &__field {
        padding-left: 8px;
        border: 0;
    }

    &__icon {
        fill: var(--color-white);
    }

    &__date-field {
        box-sizing: border-box;
        min-height: 38px;
        width: 100%;
        padding: 10px;
        border: 0;
        background-color: var(--color-white);
    }

    &__calendar {
        position: absolute;
        right: 0;
        top: 50%;
        padding-right: 5px;
        pointer-events: none;
        transform: translate(0, -50%);
    }

    &__date-label {
        pointer-events: none;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        border: 0;
        background-color: var(--color-white);
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
}

.info-module-input-filter__field::-webkit-outer-spin-button,
.info-module-input-filter__field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.info-module-input-filter__field {
    width: 100%;
    height: 100%;
    appearance: textfield;
    -moz-appearance: textfield;
}


.filters-aside-item.is-active .filters-aside-item-body.info-module-input-filter {
    display: flex;
}


.filters-aside-item-body.filters-aside-item-body--max-height {
    max-height: 250px;
}

.info-module-filter-aside .choose {
    max-width: 275px;
    display: flex;
}

.filters-aside-item--full-height {
    height: 100%;
}

.filters-aside-item-body.filters-aside-item-body--full-height {
    max-height: unset;
    height: 100%;
}

.filters-aside-item-body.filters-aside-item-body--no-scroll {
    overflow: visible;
}