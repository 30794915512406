.search-options {
    display: flex;
    align-items: center;
    gap: 15px;

    &--pad {
        gap: 0;

        @media (--breakpoint-tablet-sm-up) {
            gap: 15px;
            padding-bottom: 0;
        }
    }

    &__btn-text {
        display: none;

        @media(--breakpoint-tablet-up) {
            display: block;
        }
    }

    &__nav-search {
        flex: 1;
        width: auto;
        padding-bottom: 15px;

        &--small-scan {
            width: 100%;
        }

        @media (--breakpoint-tablet-sm-up) {
            padding-bottom: 0;
        }
    }

    @media(--breakpoint-tablet-sm-up) {
        flex-direction: row;
        align-items: center;
        padding-bottom: 0;
    }
}

.search-options .btn.btn-primary {
    min-width: auto;
}

.search-options .search-options__small-scan-btn {
    padding: 7px 14px;
}

.info-module-search-options-small-scan .product-search {
    padding: 0;
}

.search-input.im-search-input {
    border-radius: 4px;
    border: 1px solid var(--color-loght-gray);
    height: 35px;
}

.nav-search .btn-search {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 56px;
    transform: translate(1px, 0);
    background: var(--color-primary);
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 0 4px 4px 0;
}

.search-options__nav-search.nav-search {
    padding-bottom: 0;
}

.nav-search .icon-search.im-icon-search {
    width: 20px;
    height: 20px;
    fill: var(--color-white);
}

.info-module-search-options-small-scan #scanButton {
    width: auto;
    min-width: 0;
    
    @media(--breakpoint-tablet-up) {
        min-width: 215px;        
    }
}


.search-options__scan-btn-text--hide {
    display: none;

    @media(--breakpoint-tablet-up) {
        display: block;
    }
}

.search-options .btn-scanner-wrapper {
    width: auto;
    min-width: 0;
}

.search-options .btn-scanner-wrapper.btn-scanner-wrapper--smaller-padding {
    padding: 5px 10px;

    @media(--breakpoint-tablet-up) {
        padding: 15px 10px;
    }
}

#info-module .search-results-container {
    max-height: calc(100vh - 172px);
    overflow: auto;
}

@supports (height: 100svh) {
    #info-module .search-results-container {
        max-height: calc(100svh - 172px);
        overflow: auto;
    }
}

@supports (height: 100dvh) {
    #info-module .search-results-container {
        max-height: calc(100dvh - 172px);
        overflow: auto;
    }
}