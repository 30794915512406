.chat-popup {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 102;

	@media(--breakpoint-desktop-up) {
		bottom: 24px;
		right: 24px;
	}
}

.chat-popup--higher {
	bottom: 60px;
}

.chat-popup__trigger {
	width: 60px;
	height: 60px;
	border: 0;
	border-radius: 50%;
	background-color: var(--color-primary);
	cursor: pointer;
	box-shadow: 4px 0px 5px 0px rgba(138, 184, 12, 0.39);

	&--active,
	&:hover {
		background-color: #8DB325;
	}
}


.chat-inner {
	box-sizing: border-box;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-color: var(--color-white);

	@media(--breakpoint-mobile-up) {
		position: absolute;
		bottom: 65px;
		right: 0;
		left: unset;
		width: 339px;
		background-color: var(--color-white);
		border-radius: 10px;
		box-shadow: 0px 4px 13.8px 0px rgba(0, 0, 0, 0.2);
	}
}

.chat-inner__success {
	display: flex;
	flex-direction: column;
	min-height: 400px;
}

.chat-inner__success--hide {
	display: none;
}

.chat-inner__success-container {
	margin: auto 0;
	display: flex;
	flex-direction: column;
	gap: 9px;
	align-items: center;
}

.chat-inner__form {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.chat-inner__bottom {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-top: 12px;
}

.chat-inner__consent {
	display: flex;
	gap: 9px;
}

.chat-inner__privacy-notice {
	color: var(--color-black);
}

.chat-inner__privacy-policy {
	display: block;
}

.chat-option-window {
	position: absolute;
	bottom: 65px;
	right: 0;
	min-width: 235px;
	width: 80%;
	background-color: var(--color-white);
	border-radius: 10px;
	box-shadow: 0px 4px 13.8px 0px rgba(0, 0, 0, 0.2);

	@media(--breakpoint-mobile-up) {
		width: 350px;
	}

	@media(--breakpoint-desktop-up) {
		max-width: 339px;
	}
}

.chat-window {
	display: none;
	flex-direction: column;
	gap: 19px;
	padding: 20px;
}

.chat-window--active {
	display: flex;
}

.chat-head {
	display: flex;
	justify-content: space-between;
	color: var(--color-black);
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	font-family: var(--font-secondary);
}

.chat-head__close {
	border: 0;
	background-color: transparent;
	cursor: pointer;
}

.chat-option-window__option-container {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.chat-option-window__option {
	display: flex;
	align-items: center;
	padding: 11px 12px;
	gap: 6px;
	background-color: var(--color-neutral-lt);
	border: 0;
	border-radius: 5px;
	color: var(--color-black);
	text-decoration: none;
	font-size: 16px;
	line-height: 18.75px;
	font-weight: 400;
	cursor: pointer;

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
		background-color: var(--color-light-beige);
	}
}