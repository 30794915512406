.info-module-subhead {
    background-color: var(--color-white);

    &__wrapper {
        position: relative;

        @media (--breakpoint-tablet-up) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    &__container {
        display: flex;
        flex-direction: column-reverse;
        padding: 15px 0;
        
        @media (--breakpoint-tablet-sm-up) {
            flex-direction: row;
            align-items: center;
            gap: 15px;
        }
    }

    &__search-options-container {
        grid-column: 1 / 3;

        @media (--breakpoint-tablet-up){
            grid-column: 2/3;
        }

    }

    .btn-menu-text {
        vertical-align: bottom;
    }

    &__navigation {
        display:none;

        @media (--breakpoint-tablet-up){
            display: flex;
            align-items: center;
        }
    }

    &--hide-on-mobile {
        display: none;

        @media(--breakpoint-mobile-up) {
            display: block;
        }
    }

    @media(--breakpoint-tablet-up) {
        position: relative;
        background-color: var(--color-white-md);
        transition: z-index 0.3s;
        z-index: 101;
    }
}

.info-module-subhead .btn-scanner-wrapper {
    padding: 7px 0;

    @media(--breakpoint-tablet-up) {
        padding: 15px 0;
    }
}

.info-module-subhead .icon.icon-svg {
    min-width: 21px;
}

.info-module-menu-container-desktop {
    top: 100%;
    z-index: 101;
}

.info-module-subhead.info-module-subhead--hide {
    transition: z-index 0.3s;
    z-index: 1;
}

.info-module-go-back-subhead {
    position: relative;
    border-bottom: 1px solid var(--color-neutral-mid);
    background-color: var(--color-white);
    transition: z-index 0.3s;

    &__container {
        display: flex;
        justify-content: space-between;
        max-height: 41px;
        padding: 3px 0 5px 0;
    }

    &__back-btn {
        display: flex;
        align-items: center;
        gap: 5px;
        height: 33px;
        color: var(--color-black);
        font-family: var(--font-primary);
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        text-transform: uppercase;
    }

    &__stop-count-btn.btn.btn-primary {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 33px;
        cursor: pointer;
    }

    &__stop-count-btn.btn.btn-primary[disabled] {
        background-color: var(--color-text);
        pointer-events: none;
    }

    @media(--breakpoint-tablet-up) {
        z-index: 101;
    }
}

.info-module-go-back-subhead.info-module-go-back-subhead--hide {
    transition: z-index 0.3s;
    z-index: 1;
}

.info-module-subhead-container {
    display: flex;
    flex-direction: column;

    @media(--breakpoint-tablet-up) {
        flex-direction: column-reverse;
        margin-bottom: 16px;
    }
}

.info-module-subhead-container--no-margin {
    margin-bottom: 0;
}

.info-module-search-options-small-scan {
    align-items: center;
    flex-direction: row;
}

.info-module-search-options-small-scan .btn.btn-primary {
    min-width: 0;
}

.barcode-placeholder {
    display: none;
}
