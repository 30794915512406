/* ------------------------------------------------------------ *\
	#imports
\* ------------------------------------------------------------ */
@import "_header.css";
@import "_footer.css";


/* ------------------------------------------------------------ *\
	#main
\* ------------------------------------------------------------ */
.main { width: 100%; min-height: 400px; padding: 0 0 20px; background: #fff; transition: all 0.3s; }
.main:after { @apply --clearfix; }
.main .sidebar { float: left; width: 300px; }
.main .main-content { float: left; width: 1000px; }
.main .full .sidebar { display: none; }
.main .full .main-content { float: none; width: auto; }

.main-head { padding-bottom: 20px; }
.main-head:after { @apply --clearfix }
.mobile-menus { display: none; margin-bottom: 15px; background: rgba(255,255,255,0.95); }
.main-head-inner .icon-svg { float: left; width: 55px; height: 53px; margin-right: 15px; fill: #fff; }
.main-head-inner { overflow: hidden; border-radius: 5px; padding: 5px 10px; background: var(--color-secondary); }
.main-head-inner-wrap { float: left; }
.main-head-inner-wrap .title-primary { display: none; }
.main-content .pad-primary {
  overflow: visible;
  position: relative;
  border-bottom: 1px solid transparent;
}
.main-content .pad-primary .no-results{
  margin: -4px;
  padding: 50px 0;
  text-align: center;
  border: 4px solid white;
}
.main-content .pad-primary .no-results h1{
  padding: 40px 0;
  font-size: 29px;
  line-height: 24px;
  color: #6c6c6c;
}
.color-category-1.main-head-inner {
  background: var(--color-category-1);
}
.color-category-2.main-head-inner {
  background: var(--color-category-2);
}
.color-category-3.main-head-inner {
  background: var(--color-category-3);
}
.color-category-4.main-head-inner {
  background: var(--color-category-4);
}
.color-category-5.main-head-inner {
  background: var(--color-category-5);
}
.color-category-6.main-head-inner {
  background: var(--color-category-6);
}
.color-category-7.main-head-inner {
  background: var(--color-category-7);
}
.color-category-8.main-head-inner {
  background: var(--color-category-8);
}
.color-category-9.main-head-inner {
  background: var(--color-category-9);
}
.color-category-10.main-head-inner {
  background: var(--color-category-10);
}
.color-category-11.main-head-inner {
  background: var(--color-category-11);
}
.color-category-12.main-head-inner {
  background: var(--color-category-12);
}

.color-category-1.related_categories { background: var(--color-category-1); }
.color-category-2.related_categories { background: var(--color-category-2); }
.color-category-3.related_categories { background: var(--color-category-3); }
.color-category-4.related_categories { background: var(--color-category-4); }
.color-category-5.related_categories { background: var(--color-category-5); }
.color-category-6.related_categories { background: var(--color-category-6); }
.color-category-7.related_categories { background: var(--color-category-7); }
.color-category-8.related_categories { background: var(--color-category-8); }
.color-category-9.related_categories { background: var(--color-category-9); }
.color-category-10.related_categories { background: var(--color-category-10); }
.color-category-11.related_categories { background: var(--color-category-11); }
.color-category-12.related_categories { background: var(--color-category-12); }

.title-primary { font-family: var(--font-secondary); font-size: 24px; line-height: 1.2; color: #fff; text-transform: uppercase; }

.callout-login .title { margin-bottom: 4px; font-family: var(--font-secondary); font-size: 13px; line-height: 18px; color: #000; text-transform: uppercase; }
.callout-login .note { padding-bottom: 25px; }
.callout-login .btns { text-align: center; }
.callout-login .divider { margin: 10px 0; text-align: center; }

/* checkout */
.checkout { min-height: 100vh; padding: 0; }
.checkout-content { float: left; width: 780px; border-radius: 0 0 5px 5px; margin-bottom: 40px; background: #fff; box-shadow: var(--shadow-default); }
.checkout-aside { overflow: hidden; padding: 90px 15px 30px; position: sticky; top: 0; position: -webkit-sticky;}
.checkout-header { overflow: hidden; padding: 45px 15px 35px; }
.checkout-header .logo { float: left; }
.checkout-header .logo img { max-width: 214px; }
.checkout-header .user-preview { float: right; width: 210px; padding-top: 10px; }
.checkout-header .user-preview .checkout-greet a { font-weight: bold; }
.checkout-main { min-height: 600px; padding: 15px; }
.checkout-main .title-primary { margin-bottom: 20px; color: #000; text-align: center; }
.checkout-main .preview-info { padding: 20px; margin: 15px 0; }
.checkout-aside .boxes { overflow: auto; max-height: 300px; margin-bottom: 20px; }
.checkout-summary { padding: 10px 0; border-top: 1px solid var(--color-neutral-dk); }
.checkout-summary .summary-row { display: flex; padding: 5px 0; }
.checkout-summary .summary-row p { font-size: 14px; line-height: 24px; color: #000; }
.checkout-summary .summary-row .label { flex: 1; padding-right: 5px; }
.checkout-summary .summary-row .label span { display: block; font-size: 12px; line-height: 18px; color: var(--color-text); }
.checkout-summary .summary-row .price strong { font-family: var(--font-secondary); font-size: 18px; font-weight: normal; }
.checkout-summary .summary-row.total { border-top: 1px solid var(--color-neutral-dk); padding: 15px 0; }
.checkout-summary .summary-row.total p { line-height: 28px; }
.checkout-summary .summary-row.total .label { font-size: 16px; font-weight: 500; }
.checkout-summary .summary-row.total .price strong { font-size: 28px; color: var(--color-primary); }

.checkout-addresses .addresses { font-size: 0; line-height: 0; }
.checkout-addresses .addresses-item { box-sizing: border-box; position: relative; padding-bottom: 10px; }
.checkout-addresses .addresses-item .pad-basic { position: relative; padding: 15px; margin-top: 15px; }
.checkout-addresses .addresses-item .content { padding-right: 200px; }
.checkout-addresses .addresses-item .title { margin-bottom: 5px; font-family: var(--font-secondary); font-size: 13px; line-height: 18px; color: #000; text-transform: uppercase; }
.checkout-addresses .addresses-item .text { font-size: 14px; line-height: 18px; color: #000; }
.checkout-addresses .addresses-item .btns { position: absolute; right: 15px; bottom: 15px; }
.checkout-addresses .addresses-item .btns { position: absolute; right: 15px; bottom: 15px; }
.checkout-addresses .addresses-item .form-toggle { padding: 20px 0 10px 26px; }

.checkout-message { padding: 100px 0; text-align: center; }
.checkout-message .btns { padding: 20px 0; }

/* checkout step 2 */
.component-accordion-step2 .accordion-item.is-active { background: none; }
/* .component-accordion-step2 .choose-group-stripped .choose  { border: none; } */
.component-accordion-step2 .accordion-item-head { padding:10px 24px 10px 0; }
.component-accordion-step2 .accordion-item-head:not(.is-active) { border-top:1px solid #acacac; border-bottom: 1px solid #acacac; }
.component-accordion-step2 > .accordion-item:not(.is-active) + .accordion-item .accordion-item-head { border-top: none; }
.component-accordion-step2 .accordion-item + .accordion-item { border: none; }
.component-accordion-step2 .accordion-item-head legend { margin: 0; }
.component-accordion-step2 .accordion-item-head .title { font-size:13px; }
.form-checkout .component-accordion-step2 .choose-group-stripped .choose { border: none; }
.component-accordion-step2 .form .choose input:checked ~ .form-toggle,
.form-checkout .component-accordion-step2 .choose .form-toggle { display:block; }
