.info-module-product {
    display: flex;
    align-items: center;
    gap: 4px;

    &__img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 97px;
        height: 97px;
        border: 1px solid var(--color-neutral-mid);
    }

    &__info-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__name {
        overflow: hidden;
        max-height: 40px;
        font-family: var(--font-secondary);
        font-size: 17px;
        line-height: 20px;
        color: var(--color-black);
    }

    &__quantity {
        padding-bottom: 4px;
        font-size: 12px;
        line-height: 14px;

        &--darker {
            color: var(--color-darker-gray);
        }
    }

    &__quantity-text {
        color: var(--color-black);   
    }

    &__price-field {
        font-weight: 700;
        color: var(--color-black);
    }

    @media(--breakpoint-tablet-up) {
        display: flex;
        gap: 14px;
    }
}