.info-module-aside {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 24px 10px;
    transform: translate(0, 100vh);
    transition: transform 0.3s;
    background-color: var(--color-white);
    z-index: 102;

    &__item-list {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        width: 100%;
    }

    &__head-text {
        color: var(--color-black);
        font-weight: 700;
        font-family: var(--font-secondary);
        font-size: 24px;
        line-height: 24px;
    }

    &__close-btn {
        background-color: transparent;
        border: 0;
        cursor: pointer;
    }

    &__html-placeholder {
        margin-bottom: 10px;
    }

    @media(--breakpoint-tablet-sm-up) {
        width: 438px;
        height: 100%;
        transform: translate(438px, 0);
    }
}

.info-module-aside.info-module-aside--show {
    z-index: 201;
    transform: translate(0, 0);
    transition: transform 0.3s;
}

.info-module-aside .product-chunk {
    display: flex;
    flex-direction: column;
    gap: 12px;
}