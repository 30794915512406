/* ------------------------------------------------------------ *\
	# (max-width: 1500px)
\* ------------------------------------------------------------ */
@media (max-width: 1500px) {
	.btn-scroll-top {
		right: 0;
		bottom: -70px;
		width: 45px;
		height: 45px;
	}
	.btn-scroll-top:after {
		line-height: 45px;
		font-size: 10px;
	}
}

/* ------------------------------------------------------------ *\
	# (max-width: 1365px)
\* ------------------------------------------------------------ */
@media (max-width: 1365px) {
	.wrapper.extended { max-width: 100%; }
}
/* ------------------------------------------------------------ *\
	# (max-width: 1300px)
\* ------------------------------------------------------------ */
@media (--breakpoint-desktop-lg) {
	.wrapper { width: 1200px; }

	/* regions */
	.footer-left { width: 470px; padding: 15px 0; }
	.footer-right { width: 730px; }
	.footer-top { padding: 15px 15px 0; }
	.cols-footer .col { margin-bottom: 15px; }

	.main .sidebar { width: 20%; min-width: 225px; }
	.main .main-content { width: 80%; }


	/* banners */
	.banners { text-align: center; }
	.banners-item { width: 300px; margin-bottom: 15px; }
	.banners-grid .banner .picture img { height: 350px; }
	.banners-grid .swiper-pagination { display: block; }


	/* boxes */
	.box-basic { min-width: 240px; }
	.boxes-type-3 { margin: 0 -1%; }
	.box-type-3 { width: 48%; margin: 1%; }
	.box-type-4 { min-width: 400px; }
	.box-type-5 { width: 120px; }
	.boxes-partners-list .box-type-5 { width: 240px; }
	.box-type-6 { width: 23%; margin: 1%; padding: 10px; }
	.boxes-related { padding: 0; }
	.added-products { padding: 15px 0; }
	.boxes-products-list .box-basic { width: 25%; min-width: 225px; }
	.boxes-categories { margin: 0 -15px; }
	.boxes-categories .cols { margin: 0; }
	/* forms */
	.form-products-add .btn-primary { min-width: auto; width: 100%; }

	/* blocks */
	.pad-primary { overflow: visible; box-shadow: none; }
	.block-contacts .map-contacts { width: 455px; }
	.block-contacts .block-content { width: 700px; }
	.block-cart .cart-content { width: 924px; }
	.block-stores .block-content { width: 470px; }
	.block-stores .map-stores { width: 730px; }
	.checkout-aside .boxes { max-height: none; }


	.header-bottom .btn-login span { display: none; }
	.header-bottom .btn-login .icon-svg { padding: 0 20px; }
	.header-bottom .btn-menu { display: block; padding: 20px 15px; }
	.header-bottom .btn-menu span { font-size: 14px; }

	/* popups */
	.popups { right: 0;  }

}

/* ------------------------------------------------------------ *\
	# (max-width: 1200px)
\* ------------------------------------------------------------ */
@media (--breakpoint-desktop) {
	.wrapper { width: auto; padding: 0 10px; }
	.header-bottom .logo { margin: 2px 0 5px 10px;  }

	/* grid */
	.col1of5,
	.col2of5,
	.col3of5,
	.col4of5,
	.col5of5 { float: none; width: auto; padding-bottom: 15px; }
	.accent-products-promo .box-more { display: none; }
	.boxes-products-promo .box-type-2.box-more { display: inline-block !important; }
	/* regions */
	.header-bottom .wrapper { padding: 0; border-radius: 0; }
	.footer .list-links a { font-weight: 400; }
	.footer-top { padding: 15px 0; }
	.footer-top .cols-full.cols-no-gutters { margin-bottom: 15px; }
	.footer-top .cols-full.cols-no-gutters .footer-title { margin-bottom: 10px; min-height: 2.6em; font-weight: 400; }
	.footer-top > .cols-no-gutters > .col { width: 100%; }
	.footer-contacts { padding: 15px 0 0; margin: 0; border-left: none; border-top: 1px solid #fff; }
	.footer-title { margin: 0; }
	.footer-contacts .footer-title { font-family: var(--font-primary); font-weight: 500; text-transform: none; }
	.footer-title + a { padding: 10px 0; }
	.footer-hotlinks { margin: 0 0 40px; padding: 0; }
	.copyrights { padding: 5px 0; }
	.footer-hotlink { margin: 0 10px; }
	.footer-hotlink a { padding: 10px 0; }
	.socials-footer { display: block; margin: 0; }
	.socials-footer .socials-item { vertical-align: top; margin: 0; padding: 0; display: inline-block; }
	.cols-footer .col1of5 { float: left; width: 33.3%; }
	.main-head-inner .icon-svg { width: 40px; height: 38px; }
	.main .sidebar { float: none; width: auto; margin: 0 0 15px; font-size: 0; line-height: 0; }
	.nav-aside,
	.filters-aside { display: inline-block; width: 50%; margin-right: 0; box-sizing: border-box; }
	.main .main-content { float: none; width: auto; }
	.b-news-list .col4of12 { padding: 15px 0; width: 50%; }

	/* nav */
	.nav-secondary { display: none; }
	.nav-cart-head { border-radius: 0; }

	/* boxes */
	.boxes-related { padding: 0; }
	.boxes-type-4 { text-align: center; }
	.box-type-4 { min-width: auto; width: 50%; }
	.box-type-4 .content .title { height: auto; }
	.boxes-type-5 { text-align: center; }
	.box-type-2 { width: 25%; }
	.accent-products-new .box-more { float:none; display: block; width: auto; height: auto; }
	.accent .boxes-products { float: none; width: auto; max-width: 100%; }
	.box-type-8 .content { width: auto; }
	.boxes-related { justify-content: center; }
	.boxes-related .box-related { width: 50%; flex-basis: 250px; margin: 10px; }
	.accent-related .boxes-type-2 .box { max-width: 250px; outline: none; border: 1px solid var(--color-neutral-mid); border-radius: 4px; }
	.boxes-special-offers { margin: 0 -5px; }
	.boxes-special-offers.swiper-container-initialized .box-special-offer { margin: 0; }
	.boxes-special-offers .swiper-pagination { display: block; }
	.boxes-special-offers .slick-slide { padding: 0 5px;  }
	.boxes-special-offers .slick-dots { bottom: -30px; }
	.boxes-special-offers .slick-dots li { width: 14px; height: 14px; border-radius: 36px; border: 1px solid #707070; transition: .3s all; }
	.boxes-special-offers .slick-dots li button:before { content: none; }
	.boxes-special-offers .slick-dots .slick-active { border: 1px solid var(--color-primary); background: var(--color-primary); }
	.boxes-categories { margin: 0 -10px; }
	.boxes-categories .col2of10 { padding: 0 10px 10px; }

	/* forms */
	.callout-login,
	.form-login .form-title { text-align: center; }
	.form-profile .form-title { text-align: center; }
	.form-password-change .btns { padding: 10px 0; }
	.mobile-menus { display: block; z-index: 200; }
	.mobile-menus .col12of12 { width: 50%; }
	.mobile-menus .col12of12 .filters-aside-head {
		direction: rtl;
		text-align: left;
	}
	.mobile-menus.sticky { position: fixed; top: -100%; left: 0; width: 100%; box-shadow: 0 2.5px 7.5px -5px #2b2b2b; animation: slideDown 1s forwards; }
	.mobile-menus.sticky + * { padding-top: 67px; }
	.mobile-menus .filters-aside-head,
	.filters-aside-body .filters-aside-head { display: block; padding: 1.5px 0; border: 1px solid var(--color-neutral-mid); }
	.filters-aside-body .filters-aside-head .title,
	.mobile-menus .filters-aside-head .title { font-size: 18px; line-height: 1.4; font-weight: bold; color: #000; padding: 10px; }
	.mobile-menus .nav-aside-head { display: block; padding: 1.5px 0; background: none; border-radius: 0; border: 1px solid var(--color-neutral-mid); }
	.mobile-menus .nav-aside-head .title { font-size: 18px; line-height: 1.4; font-weight: bold; color: #000; padding: 10px; }
	#js-form-filter .sidebar { position: relative; }
	.filters-aside-body { position: fixed; top: 0; bottom: 0; right: 0; padding: 0 15px 15px; width: 50%; background: #ffffff; z-index: 105; transform: translateX(101%); transition: .4s transform ease-in-out, top .4s .65s ease-in-out; }
	.filters-aside-body.is-active { transform: translateX(0); overflow: auto; }
	.filters-aside-body.is-active .filters-aside { transform: translateX(0); }
	.filters-aside-body .filters-aside-head { position: relative; margin: 0 -15px 15px; border-width: 0 0 1px 0;}
	.filters-aside-body .filters-aside-head:before,
	.filters-aside-body .filters-aside-head:after { content: ''; position: absolute; top: calc(50% - 1px); right: 15px; background: #000; transform: rotate(45deg); }
	.filters-aside-body .filters-aside-head:before { width: 18px; height: 1px; }
	.filters-aside-body .filters-aside-head:after { width: 1px; height: 18px; top: calc(50% - 9px); right: 23px; opacity: 1; transition: .25s all;}
	.filters-aside-item-head { padding: 5px; margin-bottom: 15px; }
	.filters-aside-item-head .title { padding: 5px 15px; font-size: 16px; font-weight: 700; color: #000; }
	.filters-aside-item-body { padding-left: 10px; margin-right: 10px; max-height: 156px; }
	.filters-aside-item-body .choose { padding-bottom: 10px; }
	.nav-aside { position: fixed; top: 0; left: 0; bottom: 0; margin-bottom: 0; overflow-y: auto; padding: 0 0 15px; width: 50%; background: #ffffff; z-index: 105; transform: translateX(-101%); transition: .4s transform ease-in-out, top .4s .65s ease-in-out; }
	.nav-aside:before { content: none; }
	.nav-aside li { display: block; }
	.nav-aside li > a { display: inline-block; padding: 5px 0; font-size: 16px; line-height: 1.25; }
	.nav-aside li.dropdown a { width: calc(100% - 20px); }
	.nav-aside .nav-aside-body { display: block; }
	.nav-aside.has-flag-mobile { transform: translateX(0); }
	.nav-aside .nav-aside-head { background: none; border-radius: 0; border-bottom: 1px solid var(--color-neutral-mid); }
	.nav-aside .nav-aside-head .title { font-weight: 700; background: none; padding: 10px; font-size: 16px; line-height: 1.3; color: #000; }
	body.global-active .overlay-global { opacity: 0.7; visibility: visible; pointer-events: auto; z-index: 101;  }
	body.global-active .main { z-index: auto; }
	.check > span.brands { margin-left: 5px; }



	/* accents */
	.accent-title { margin-bottom: 10px; text-align: center; }
	.accent-parallax .parallax-container,
	.accent-parallax .parallax { display: none; }
	.accent-parallax .banner { height: 350px; }

	.banners-grid .slick-dots { bottom: -30px; }
	.banners-grid .slick-dots li { width: 14px; height: 14px; border-radius: 36px; border: 1px solid #707070; transition: .3s all; }
	.banners-grid .slick-dots li button:before { content: none; }
	.banners-grid .slick-dots .slick-active { border: 1px solid var(--color-primary); background: var(--color-primary); }

	/* blocks */
	.block-products-view .block-products-price { margin-bottom: 0; }
	.block-products-view .block-content {
		grid-template-columns: auto;
		grid-auto-rows: auto;
		grid-template-areas:
		"preview preview"
		"title title"
		"description aside"
		"variations aside"
		"utils aside"; }

	/* Product detailed page */
	.block-products-view .product-detailed-content {
		grid-template-columns: minmax(0, 1fr);
		max-width: 100%;
		grid-template-areas:
		"preview"
		"title"
		"description"
		"variations"
		"aside"
		"utils";
	}

	.product-variations {
		grid-area: variations;
	}
	.product-variations { grid-template-columns: 1fr 1fr; }
	.block-products-view .block-top .block-preview { float: none; width: auto; margin-right: 0; }
	.block-products-view .block-top .block-preview .picture { text-align: center; }
	.block-products-view .block-content-bottom { display: none; }
	.block-products-view .block-preview .picture { max-width: 100%; margin: 0 auto; }
	.block-stores .block-content { float: none; width: auto; height: 300px; }
	.block-stores .map-stores { position: relative; top: auto; right: auto; bottom: auto; width: auto; height: 300px; }
	.block-contacts .block-content { float: none; width: auto; }
	.block-contacts .map-contacts { position: relative; top: auto; bottom: auto; left: auto; width: auto; height: 300px; }
	.block-gallery .gallery-news { float: none; max-width: 100%; height: auto; margin: 0 auto; }
	.block-entrance .col { float: none; width: auto; padding-bottom: 20px; }
	.block-entrance .form-basic { padding: 30px 0; }
	.block-small .form-basic { padding: 30px 15px; }
	.block-cart .cart-content { float: none; width: auto; }
	.block-cart .cart-aside { float: none; width: auto; }
	.block-cart .cart-aside:before { display: none; }

	/* checkout */
	.checkout-content { float: none; width: auto; }
	.checkout-content .steps { text-align: center; }
	.checkout-aside { padding: 15px; }
	.checkout-addresses .addresses-item .content { padding-right: 0; }

	/* components */
	.tabs-content .pad-primary { box-shadow: none; }
	[class*="accent-products-"] .pad-primary .box-basic { margin-right: 15px; max-height: 370px; overflow: hidden; outline: none; border-radius: 4px; border: 1px solid #dedede; }
	.component-tabs { background: none; }
	.component-tabs .tab { border: none; }
	.component-tabs .tabs-nav { display: none; }
	.component-tabs .tab-head { position: relative; display: block; color: #3c3c3c; margin-bottom: 10px; border-radius: 4px; }
	.component-tabs .tab-head:before,
	.component-tabs .tab-head:after { content: ''; position: absolute; top: calc(50% - 1px); right: 15px; background: #000; }
	.component-tabs .tab-head:before { width: 18px; height: 2px; }
	.component-tabs .tab-head:after { width: 2px; height: 18px; top: calc(50% - 9px); right: 23px; opacity: 1; transition: .25s all;}
	.component-tabs .tab-head.is-active:after { opacity: 0; transform: rotate(-90deg); }
	.component-gallery-item { width: 23%; }
	.boxes-products-full .swiper-pagination,
	.boxes-products-promo .swiper-pagination { display: block; }
	/*messages and popups*/

	.popups { right: auto; width: 100%; }
	.popup-discount-offer.offer,
	.popup-discount-offer.timer { padding: 15px 15px 0; }
	.popup-discount-offer .content { padding: 5px; }
	.popup-discount-offer.shrink { padding: 5px; }

	.product-detailed-picture img {
		max-width: 310px;
	}

	.tabs-product-detailed .tab-head {
		margin-bottom: 0;
	}

	.tabs-product-detailed .open .tab-head:after {
		content: none;
	}

	.tabs-product-detailed .open .tab-head:before {
		content: none;
	}

	.black-friday-swiper {
		padding-top: 10px;
	}

	.boxes-product-detailed .swiper-wrapper .swiper-slide .picture img {
		width: auto;
	}

	.product-detailed-barcodes {
		grid-template-columns: 1fr;
	}

	.product-detailed-barcodes-image {
		max-width: 200px;
		margin: 0 auto;
	}

	.low-availability-body {
		left: 0;
	}
}

@media (min-width: 1200px) {
	.btn-stores.is-active,
	.btn-search.is-active,
	.btn-search:hover,
	.btn-contacts:hover { background: var(--color-primary); }
	.btn-login.is-active .icon-svg,
	.btn-login:hover .icon-svg,
	.btn-contacts.is-active .icon-svg,
	.btn-stores.is-active .icon-svg,
	.btn-contacts:hover .icon-svg { fill: #fff; }
	.btn-search.is-active .icon-search,
	.btn-login.is-active .icon-login,
	.btn-stores.is-active .icon-stores
	.btn-contacts.is-active .icon-contacts { opacity: 0; visibility: hidden; transition: none; }
	.btn-search.is-active .icon-close,
	.btn-stores.is-active .icon-close,
	.btn-login.is-active .icon-close,
	.btn-contacts.is-active .icon-close { opacity: 1; visibility: visible; transition: all 0.3s; }
}


/* ------------------------------------------------------------ *\
	# (max-width: 991px)
\* ------------------------------------------------------------ */
@media (--breakpoint-tablet) {

	/* navigation */
	.nav-primary.is-active { display: none; }
	.nav-mobile.is-active { display: block; box-shadow: inset 0 1px 4px #dedede; }
	.header { z-index: 31; }
	.header-bottom { background: #f7f7f7; }

	/*accents*/
	 .accent-news .col { width: 100%; }
	 .accent-news .news-item { margin: 0 0 10px; }
	 .accent-news .slick-dots { bottom: -30px; }
	 .accent-news .slick-dots li { width: 14px; height: 14px; border-radius: 36px; border: 1px solid #707070; transition: .3s all; }
	 .accent-news .slick-dots li button:before { content: none; }
	 .accent-news .slick-dots .slick-active { border: 1px solid var(--color-primary); background: var(--color-primary); }
	/* article */
	.table-grid,
	.table-grid thead,
	.table-grid tbody,
	.table-grid tr,
	.table-grid th,
	.table-grid td { display: block; width: auto !important; }
	.table-grid img { margin: 0 !important; }

	/* nav */
	.nav-content ul li { display: block; text-align: center; }
	.btn-menu-text { display: none; }

	/* forms */
	.form-profile .col { float: none; width: auto; }
	.form-profile .form-row-inline { padding-bottom: 10px; }
	.form-legal .btns,
	.form-personal .btns { padding: 10px 0; }

	/* boxes */
	.box-special-offer { width: 75%; }
	.boxes-products-list .box-basic { width: var(--col-4); }
	.boxes-type-1 { display: block; text-align: center; }
	.box-type-1 { width: 50%; outline: none; }
	.box-type-2 { width: 50%; }
	.box-type-6 { width: 48%; }
	.boxes-related { justify-content: center; }
	.box-related { flex-basis: 250px; margin: 10px; }
	.boxes-categories .col { padding: 7.5px; max-width: 205px; min-width: 205px; }
	.boxes-categories .col3of12 { padding: 7.5px; max-width: 25%; min-width: 205px; }
	.boxes-categories .swiper-navigation { display: block; }

	/* tables */
	.table-cart th,
	.table-cart td { padding: 5px; }
	.table-cart .picture { width: 40px; }
	.table-cart .title { padding: 15px 5px; }

	/* blocks */
	.block-profile .block-title,
	.block-profile .block-note { text-align: center; }
	.block-profile-personal .addresses { margin: 0; }
	.block-profile-personal .addresses-item { display: block; width: auto; padding: 0; margin: 0 0 15px; }
	.block-profile-history .col { float: none; width: auto; padding-bottom: 15px; }

	.block-products-view .block-content {
		grid-template-columns: minmax(0, 1fr);
		max-width: 100%;
		grid-template-areas:
		"preview"
		"title"
		"description"
		"variations"
		"aside"
		"utils"; }
	.block-products-view .block-top .block-preview { max-width: 100%; }

	/* checkout */
	.checkout .steps .steps-item { margin: 5px; }

	/* components */
	.accordion-item-head .title { font-size: 12px; line-height: 1.3; }
	.accordion-item-head { padding: 15px 60px 15px 15px; }
	.accordion-item-body .article { padding: 0 15px 15px; }

	/* messages */

	/* swiper wrapper animation */
	.swiper-wrapper,
	.swiper-wrapper-animation { animation-name: bounce-x; animation-duration: 1s; animation-iteration-count: 1;}

   /* unsubscribe page */
   .unsubscribe-container {
    height: auto;
    transform: translate(0);
    margin-bottom: 50px;
  }
  .unsubscribe-container .unsubscribe-left {
    width:80%;
    margin: 50px auto;
    float: none;
  }
  .unsubscribe-container .unsubscribe-right {
    width:50%;
    float: none;
    margin: auto;
  }
  .unsubscribe-container .unsubscribe-left p {
    width: 100%;
  }

  .low-availability-body {
	top: unset;
	left: 0;
	bottom: 48px;
}

}

/* ------------------------------------------------------------ *\
	# (max-width: 767px)
\* ------------------------------------------------------------ */
@media (--breakpoint-tablet-sm) {
	/* regions */
	.header-bottom .cols { font-size: 0; line-height: 0; }
	.header-bottom .cols .col { float: none; display: inline-block; vertical-align: middle; }
	.header-bottom .col7of12 { width: var(--col-8); }
	.header-bottom .col3of12 { width: var(--col-2); }

	.table-cart-quick thead th { padding-bottom: 20px; }


	.header-bottom .nav-cart { position: static; margin: 0 auto; padding-right: 15px; }
	.header-bottom .nav-cart .icon-svg { width: 25px; height: 25px; }
	.header-top .btn-login { display: inline-block; padding-right: 0; }
	.header-top .btn-login span,
	.header-top .btn-stores span,
	.header .nav-phone span { display: none; }
	.header .nav-phone .icon-svg,
	.header .btn-stores .icon-svg,
	.header-top .btn-login .icon-svg { height: 25px; width: 25px; margin: 0; }
	.mobile-menus .col12of12 { width: 100%; }

	.cols-footer .col { float: none; width: auto; text-align: center; }
	.footer-top .cols-full .col { float: none; display: block; width: auto; max-width: var(--col-6); margin: 0 auto;  padding: 20px 0; vertical-align: top; text-align: center;  border-bottom: 1px solid rgb(147, 147, 147); }
	.footer-top .cols-full .col .footer-title { margin-bottom: 0; }
	.footer-top .list-links { margin: 0; }
	.footer-contacts { font-size: 0; line-height: 0; max-width: var(--col-8); margin: 0 auto; border: none; text-align: justify; }
	.footer-contacts .footer-title { text-align: center; }
	.footer-contacts:after { content:''; display: inline-block; width: 100%; }
	.footer-contacts > a { display: inline-block; margin-right: 25px; vertical-align: middle; }
	.footer .socials-footer { display: inline-block; margin: 0; vertical-align: middle; }
	.footer-bottom { text-align: center; }
	.footer-bottom .copy,
	.footer-bottom .trademark { float: none; padding-bottom: 10px; }

	/* btns */
	.btn-search { width: 50px; height: 50px; }
	.btn-search .icon-svg { width: 20px; height: 20px; }
	.btn-menu { padding: 13px 10px; }
	.header-bottom .btn-login { display: none; }

	/* nav */
	.nav-cart-head { padding: 0;  }
	.nav-cart-head .icon-cart { width: 22px; height: 22px; }
	.nav-cart-head sup { top: -10px; right: -5px; }


	/* forms */
	.form .captcha { float: none; width: 304px; margin: 0 auto; }
	.form .choose-group-stripped .form-hint { float: none; }
	.form .choose-group-stripped .badge-payment { float: none; margin: 15px 0 5px; text-align: center; }
	.form-basic .col { float: none; width: auto; }
	.form-search { padding: 10px 0; }
	.form-search .field input { padding-left: 20px; }
	.form-search .icon-search { display: none; }
	.form-contacts .form-title { text-align: center; }
	.form-contacts .col { float: none; width: auto; }
	.form-contacts .btns { float: none; padding-top: 10px; text-align: center; }
	.form-newsletter .form-controls { display: block; }
	.form-newsletter .btn { display: block; width: 150px; margin: 10px auto 0; }
	.form-checkout .col { float: none; width: auto; }
	.form-checkout legend { text-align: center; }
	.form-checkout form > .btns { padding: 5px 0; text-align: center; }
	.form-checkout form > .btns .btn-article,
	.form-checkout form > .btns .btn-primary { float: none; margin: 10px 0; }
	.form-checkout form > .btns .btn-article { font-size: 9px; }
	.form-products-add .col { width: auto; }
	.nav-aside,
	.filters-aside-body { width: 80%; }
	/* boxes */
	.box-type-1 .picture { float: none; display: inline-block; vertical-align: top; }
	.boxes-products-promo,
	.boxes-products-new { text-align: center; }
	.box-type-2 { display: block; max-width: 400px; width: auto; margin: 0 auto; }
	.boxes-products-list .box-type-2 { display: inline-block; width: var(--col-6); }
	.boxes-type-3 { margin: 0; }
	.box-type-3 { display: block; width: auto; margin: 0 0 10px; }
	.box-type-4 { display: block; width: auto; }
	.box-type-6 { display: block; width: auto; }
	.box-type-9 .picture { width: 70px; }
	.boxes-special-offers { margin: 0 -5px; }
	.boxes-special-offers .slick-slide { padding: 0 5px; }
	.box-special-offer .overlay .content .text p { white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
	.box-special-offer .overlay { padding: 5px 15px;  }
	.box-special-offer .overlay .icon-svg { margin-right: 15px; }
	.box-special-offer .picture img { min-height: 275px; object-fit: cover; }
	/* tables */
	.table-wrap { overflow: auto; }
	.table-cart { min-width: 500px; }

	/* accents */
	.accent-parallax .banner { height: 500px; background-position: center center; }
	.accent-parallax .overlay { right: 0; padding: 0 15px; width: auto; background: linear-gradient(to bottom, #000 0%, transparent 100%); text-align: left; }
	.accent-parallax .overlay .title { font-size: 34px; line-height: 1.3; }
	.accent-parallax .banner.cardbox { height: 500px; background-position: 85%; }
	.accent-parallax .banner.cardbox .overlay { background: linear-gradient(to bottom, #000 0%, transparent 75%); width: auto; }
	.accent-parallax .banner.cardbox .overlay .title { max-height: none; text-align: left; }
	.accent-products .tabs-nav .tabs-mobile { display: block; }
	.accent-products .tabs-nav .tabs-mobile:after { content: '\e908'; display: inline-block; margin-left: 5px;  transform: rotate(90deg); color: var(--color-primary); font-size: 14px; line-height: 0.8; font-family: 'gs-iconfont'; font-weight: bold; transition: .25s all;  }
	.accent-products .tabs-nav.is-active .tabs-mobile { color: var(--color-primary); }
	.accent-products .tabs-nav.is-active .tabs-mobile:after { color: #b2b2b2; }


	.accent-products .tabs-nav { position: relative; width: 260px; }
	.accent-products .tabs-nav ul { position: absolute; left: 0; visibility: hidden; opacity: 0; width: 100%; z-index: 101; transform: scale3d(0.9, 0, 0.9); transform-origin: top; transition: .3s all; }
	.accent-products .tabs-nav.is-active ul { visibility: visible; opacity: 1; transform: scale3d(1, 1, 1);  }
	.accent-products .tabs-nav ul li,
	.accent-products .tabs-nav ul li:last-child { position: relative; display: block; border-width: 0 1px 1px; background: #fff; border-radius: 0 0 4px 4px; }
	.accent-products .tabs-nav ul li:before { @apply --transparent-bg; }
	.accent-products .tabs-nav ul li:after { content: none; }
	.accent-news .accent-title,
	.accent-products .accent-title,
	.accent-special-offers .accent-title { font-size: 18px; }





	/* blocks */
	.block-products-view .title-wrap { float: none; padding: 10px 0; }
	.block-products-view .logo-brand { float: none; }
	.block-products-view .block-content-middle { margin-top: 10px; }
	.block-products-view .block-products-price>.cols:first-child .col { width: auto; }
	.block-products-view .block-content-middle > .cols > .col { float: none; width: auto; }
	.block-products-view .block-content-middle > .cols > .col > .btns { padding: 10px 0 20px; }
	.block-products-view .block-products-price .col { float: none; padding-bottom: 10px; flex-grow: 1; }
	.block-products-view .products-utilities .btns { float: none; width: auto; }
	.block-products-view .products-utilities .btn { display: block; }
	.block-products-view .products-utilities .socials-share { float: none; margin-top: 15px; }
	.block-products-view .price-primary { text-align: center; }
	.block-products-view .rich-text { max-height: 100%; }
	.block-stores .stores-item .col { float: none; width: auto; padding-bottom: 10px; }
	.block-profile-personal .addresses-item .content { padding-right: 50px; }
	.block-profile-personal .addresses-item .btns { padding-top: 10px; }
	.block-profile-personal .addresses-item .btn-article { position: static; right: auto; bottom: auto; }
	.block-profile-personal .block-content > .btns { padding: 10px 0; }
	.block-profile-history .orders-history .col { float: none; width: auto; }
	.b-news-list .col4of12 { width: 100%; }

	/* checkout */
	.checkout-header { padding: 15px; }
	.checkout-header .logo { margin-bottom: 15px; float: none; text-align: center; }
	.checkout-header .user-preview { float: none; width: auto; text-align: center; }
	.checkout .steps .steps-item { width: auto; }
	.checkout .steps .steps-item .label { display: none; }
	.checkout .steps .steps-item.is-selected .label { display: block; }
	.checkout-aside { padding: 0; }

	/* components */
	.component-gallery-item { width: 48%; }

	.popup-discount-offer.offer,
	.popup-discount-offer.timer { max-width: unset; width: 100%; }
	.popup-discount-offer.timer.shrink { max-width: unset; width: 100%; }

	.popup-container .discount-container .discount-top .discount-persent { font-size: 10vw; }
	.popup-container figure .reg-popup { width: 100%; height: 100%; }
	.popup-container figure .reg-popup .discount-container { width: 100%; }

	/* search */
	.search-results-categories {
		max-height: 4.3125rem;
		padding-right: 0.75rem;
	}

	.products-locations {
		display: block;
	}

	.products-locations-text {
		margin-bottom: 14px;
	}

	/* Product detailed */
	.fields-wrapper {
		display: grid;
		grid-template-areas: "barcode barcode" "reader reader" "nav search";
		grid-template-columns: 60px 1fr;
		gap: 0;
	}

	.fields-wrapper .reader {
		grid-area: reader;
	}

	.fields-wrapper .navigation {
		grid-area: nav;
	}

	.fields-wrapper .nav-search {
		grid-area: search;
		max-width: 100%;
	}

	.product-search {
		padding: 0 10px;
	}

	.btn-scanner-wrapper {
		grid-area: barcode;
		padding: 15px 10px;
	}

	.product-detailed-title .block-title {
		margin-bottom: 0;
	}

	.products-code-wrapper {
		flex-direction: column;
		margin-bottom: 20px;
	}

	.tabs-product-detailed .tabs-head::after {
		content: none;
	}

	.tabs-product-detailed-body {
		display: block;
	}

	.tabs-product-detailed-button {
		max-width: 100%;
	}

	.product-detailed-calculator-wrapper,
	.client-price-modal__wrapper,
	.client-info-modal__wrapper {
		max-width: 310px;
	}

	.product-detailed-content .btn-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.product-detailed-content .btn.btn-secondary {
		min-width: 25%;
	}

	.product-detailed-content .btn.btn-secondary.copy-btn,
	.product-detailed-content .btn.btn-secondary.print-btn,
	.product-detailed-content .btn.btn-secondary.low-availability-btn,
	.product-detailed-content .btn.btn-secondary.client-price-btn {
		padding: 16px;
		font-size: 10px;
		line-height: 1.2;
	}

	.client-modal__wrapper {
		max-width: 328px;
	}

	.tabs-product-detailed-container .tabs-product-detailed-download {
		flex-direction: column;
		gap: 15px;
	}

	.tabs-product-detailed-download .download-btn {
		width: 100%;
	}

	.tabs-warehouses .tabs-body {
		border: 0;
	}

	.tabs-product-detailed-tables {
		display: block;
		padding: 0;
	}

	.tabs-product-detailed-warehouses-wrapper {
		max-width: 100%;
		padding: 0;
	}

	.tabs-product-detailed-article {
		padding: 12px 15px;
	}

	.table-price-warehouses-content div:nth-of-type(1) {
		flex-basis: 35%;
	}

	.tabs-product-detailed-warehouses-head span:first-of-type,
	.table-price-warehouses-elements span:nth-of-type(1) {
		flex-basis: 35%;
	}

	.table-price-warehouses-button {
		margin-left: 8px;
	}

	/* Info module search */
	.info-module-search {
		padding: 56px 0;
	}

	.info-module-search-title {
		font-size: 18px;
		line-height: 1.5;
		margin-bottom: 40px;
	}

	.info-module-search-image {
		max-width: 220px;
		height: 215px;
	}

	.box-type-2 .ribbon {
		padding: 5px;
		max-width: 85px;
		word-wrap: break-word;
		text-align: center;
	}

	.low-availability-summary {
		padding: 27px 20px;
	}

	.low-availability-summary_table thead th {
		padding-bottom: 0;
	}

	.low-availability-summary-bottom .btn-primary { 
		width: 100%;
	}

	.low-availability-summary_table thead tr {
		padding-bottom: 6px;
	}
}


/* ------------------------------------------------------------ *\
	# (max-width: 479px)
\* ------------------------------------------------------------ */
@media (--breakpoint-mobile) {
    /* body.is-active .overlay-global { opacity: 0; } */


	/* regions */
	.header-top .contact-us { font-size: 0; line-height: 0; }
	.header-top .contact-us a { font-size: 13px; line-height: 18px; }
	.footer-top .cols-full .col { max-width: var(--col-8); }
	.footer-contacts { max-width: var(--col-10); }
	.footer-contacts > a { font-size: 24px; margin-right: 15px; }
    .footer-hotlink a { font-size: 12px; }

	/* boxes */
	.boxes-type-10 { max-width: 100%; margin: 1px; }
	.box-type-1 { display: block; width: auto; }
	.box-type-3 .overlay { padding-right: 20px; padding-left: 20px; }
	.box-type-3 .overlay .icon-svg { width: 40px; height: 40px; margin-right: 15px; }
	.box-type-3 .overlay .content .title { font-size: 14px; }
	.box-type-3 .overlay .content .text { display: none; }
	.boxes-products-promo .swiper-pagination { display: none; }
	.b-news-list .news-item .content .title { font-size: 14px; margin-bottom: 10px; height: 54px; }
	.b-news-list .news-item .picture { width: 115px; height: 110px; }
	.news-item .content { padding: 0; }

	.boxes-type-3.categories .box-type-3 { width: auto; padding: 5px 0; border-left: 3px solid; background: #F8F8F8; }
	.boxes-type-3.categories .box-type-3 .overlay { background: none; position: relative; padding: 5px 10px; }
	.boxes-type-3.categories .box-type-3 .overlay .content { position: relative; color: #000; width: calc(100% - 60px);  }
	.boxes-type-3.categories .overlay .content .title { position: relative; font-size: 14px; height: auto; max-height: 45px; }
	.boxes-type-3.categories .overlay .content:after { content: '\e908';  display: block; position: absolute; right: 0; top: calc(50% - 0.6em); font-family: 'gs-iconfont'; font-size: 1rem; line-height: 1.2;  }
	.boxes-type-3.categories .box-type-3 .overlay .icon-svg { fill: inherit; }
	.boxes-type-3.categories .box-type-3 .picture { display: none; }
	.boxes-type-3.categories .box-type-3 .picture img { width: 100%; height: 100%; object-fit: cover; }
	.color-category-1.boxes-type-3 .box-type-3 { fill:var(--color-category-1); border-color: var(--color-category-1); }
	.color-category-2.boxes-type-3 .box-type-3 { fill:var(--color-category-2); border-color: var(--color-category-2); }
	.color-category-3.boxes-type-3 .box-type-3 { fill:var(--color-category-3); border-color: var(--color-category-3); }
	.color-category-4.boxes-type-3 .box-type-3 { fill:var(--color-category-4); border-color: var(--color-category-4); }
	.color-category-5.boxes-type-3 .box-type-3 { fill:var(--color-category-5); border-color: var(--color-category-5); }
	.color-category-6.boxes-type-3 .box-type-3 { fill:var(--color-category-6); border-color: var(--color-category-6); }
	.color-category-7.boxes-type-3 .box-type-3 { fill:var(--color-category-7); border-color: var(--color-category-7); }
	.color-category-8.boxes-type-3 .box-type-3 { fill:var(--color-category-8); border-color: var(--color-category-8); }
	.color-category-9.boxes-type-3 .box-type-3 { fill:var(--color-category-9); border-color: var(--color-category-9); }
	.color-category-10.boxes-type-3 .box-type-3 { fill:var(--color-category-10); border-color: var(--color-category-10); }
	.color-category-11.boxes-type-3 .box-type-3 { fill:var(--color-category-11); border-color: var(--color-category-11); }
	.color-category-12.boxes-type-3 .box-type-3 { fill:var(--color-category-12); border-color: var(--color-category-12); }
	.box-type-4 .picture { float: none; margin: 0 auto 10px; }
	.box-type-10 { width: auto; }
	.main-content .pad-primary { margin: 0 -10px; }
	.boxes-categories { padding-bottom: 30px; margin-bottom: 0; }
	.boxes-categories .box-categories { width: 100%; }
	.boxes-products-list .box-basic { max-width: 100%; width: 100%; padding: 7.5px 10px 5px; text-align: left; font-size: 0; line-height: 0; }
	.boxes-products-list .box-basic .picture,
	.boxes-products-list .box-basic .content { margin: 0; display: inline-block; vertical-align: top; }
	.boxes-products-list .box-basic .content { width: 100%; max-width: calc(100% - 100px); }
	.boxes-products-list .box-basic img { width: 100px; height: 100px; }
	.boxes-products-list .box-basic .title { font-size: 14px; line-height: 1.143; height: 48px; margin: 0;  }
	.boxes-products-list .box-basic .box-price-wrap { padding: 0; border: none; }
	.box-type-2 .box-price-new strong, .box-type-2 .box-price strong { font-size: 21px; }

	/* forms */
	.form-password-change .pad { padding: 15px 10px 0; }
	.form-newsletter .pad-basic { padding: 15px 10px 0; }

	/* paging */
	.paging ul li span,
	.paging ul li a { padding: 6px; }

	/* checkout */
	.checkout .steps .steps-item .number { width: 28px; height: 28px; font-size: 12px; line-height: 28px; }
	.checkout .steps .steps-item .label { font-size: 12px; line-height: 1.3; }

	/* colorbox */
	#cboxClose { top: 5px; right: 5px; }
	#cboxPrevious { left: 5px; }
	#cboxNext { right: 5px; }

	.header-bottom .nav-phone { display: none; }

	.nav-cart-summary { padding: 15px; }
	.nav-cart-summary .title { font-size: 18px;	}
	.nav-cart-summary .btn-close { top: 10px; right: 5px; }
	.header-bottom .nav-cart .icon-close { width: 20px; height: 20px; }
	.nav-secondary-mobile .nav-phone { display: block; }
	.nav-cart-head.is-active { left: 90px; }
	.nav-cart-summary .title { margin: 3px 35px; }

	/*popups*/
	.popup-discount-offer.offer { min-width: auto; }


	.buy-now-product-popconfirm .added-product-container .added-product-msg { margin-bottom:35px; }
	.buy-now-product-popconfirm .image-heading-container,
	.buy-now-product-popconfirm .product-container-information > .product-image,
	.buy-now-product-popconfirm .product-container-information .product-data > .product-heading { display: none; }
	.buy-now-product-popconfirm .product-container-information .product-data .image-heading-container-re .product-image  { padding:0 15px 0 0; height:100%; }
	.buy-now-product-popconfirm .image-heading-container .product-heading { flex: 4; }
	.buy-now-product-popconfirm .product-container-information .product-data .image-heading-container-re { display: flex }
	.buy-now-product-popconfirm .product-container-information .product-data .product-quantity-prices { margin-bottom:40px; }
	.buy-now-product-popconfirm .product-container-information .product-data .btns-container { flex-direction: column-reverse; }
	.buy-now-product-popconfirm .product-container-information .product-data .btns-container { margin-bottom: 30px; }
	.buy-now-product-popconfirm .product-container-information .product-data .btns-container .btns,
	.buy-now-product-popconfirm .product-container-information .product-data .btns-container .btns .btn {  width:100%; }
	.buy-now-product-popconfirm .product-container-information .product-data .btns-container .btns:nth-child(2) { margin-bottom:20px; }
	.buy-now-product-popconfirm .product-container-information .product-data .btns-container .btn-link { padding:0; }

	/* fast delivery popconfirm */
	.fast-delivery-popconfirm { top:0; left:0; transform: translate(0); width: 100%; }
	.fast-delivery-popconfirm .col-btns { flex-direction: column-reverse; width: 100%; }
	.block-products-view .fast-delivery-popconfirm .col-btns > .col,
	.fast-delivery-popconfirm .col-btns > .col .btns > .btn { width: 100%; }
	.fast-delivery-popconfirm .col-btns>:first-child { justify-content: center; }
	.fast-delivery-popconfirm .col-btns>:nth-child(2) .btns { margin-bottom:10px; padding:0; }
	.fast-delivery-popconfirm .drawer-line { margin-bottom: 40px; }

	.popconfirm > *:first-child { top:0; left:0; transform: translate(0); width:100%; z-index:31; }

	/* components */
	.product-variations {
		grid-template-columns: minmax(0, 1fr);
		grid-gap: 0;
	  }
	.component-accordion-step2 .accordion-item-head .icon-svg { right: 10px; }
	.component-accordion-step2 .accordion-item-head .icon-svg.icon-bg { right: 0; }

  /* unsubscribe page */
  .unsubscribe-container .unsubscribe-left h1 {
    font-size: 27px;
  }
  .unsubscribe-container .unsubscribe-right {
    width: 65%;
  }

  /* search */
  .search-results-categories {
    overflow: hidden;
  }
  .search-results-categories.open {
	  height: auto;
  }

  .form-nav-search .search-results li:first-child,
  .form-search .search-results li:first-child {
	  border-top: 1px solid #e6e6e6;
  }

  /* scroll top button */
  .btn-scroll-top {
	right: 10px;
  }

  .boxes-products-list .box-type-2 {
	display: flex;
	align-items: center;
	gap: 5px;
  }
}

/* ------------------------------------------------------------ *\
	# (max-width: 359px)
\* ------------------------------------------------------------ */
@media (max-width: 359px) {
	/* forms */
	.form .captcha { width: auto; }

	/*Cart product*/
	.nav-cart-head.is-active { left: 120px; }
	.nav-cart-summary .title { margin: 3px 35px; }

   /* unsubscribe page */
   .unsubscribe-container .unsubscribe-left h1 {
    font-size: 23px;
  }
  .unsubscribe-container .unsubscribe-left p {
    font-size: 14px;
  }

}
