.info-module-deliveries-list {
    margin-bottom: 120px;

    &__head {
        padding: 24px 0;
        color: var(--color-black);
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
    }

    &__status {
        display: flex;
        justify-content: center;
        padding: 4px 7px;
        border-radius: 3px;
        width: fit-content;
        font-size: 12px;

        &--partial {
            background-color: var(--color-light-red);
            color: var(--color-redish);
        }

        &--delivered {
            background-color: var(--color-light-blue);
            color: var(--color-dark-blue);
        }
    }

    &__delivery {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid var(--color-neutral-mid);
    }

    &__delivery-info {
        display: flex;
        flex-direction: column;
    }

    &__date {
        color: var(--color-black);
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }

    &__product-count {
        color: var(--color-black);
        font-size: 14px;
        line-height: 21px;
    }

    @media(--breakpoint-tablet-up) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}