.info-module-revision-category-filter {
    position: relative;
    margin-bottom: 10px;
    line-height: 1.2;
}

.info-module-revision-category-filter.has-flag {
    padding-bottom: 10px;
}

.info-module-revision-category-filter:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    z-index: -1;
    pointer-events: none;
}

.info-module-revision-category-filter>ul {
    margin-top: -2px;
    padding-bottom: 10px;
    border: 1px solid var(--color-neutral-mid);
    border-top: none;
}

.info-module-revision-category-filter__head {
    position: relative;
    display: block;
    background: var(--color-neutral-mid);
    border: 0;
    border-radius: 5px;
    padding: 5px;
}

.info-module-revision-category-filter__head .title {
    padding: 5px 15px;
    font-size: 16px;
    font-weight: 700;
    color: #000;

    @media(--breakpoint-desktop-up) {
        padding: 5px 10px;
        font-size: 14px;
    }
}

.info-module-revision-category-filter__head:before,
.info-module-revision-category-filter__head:after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 10px;
    background: #000;
}

.info-module-revision-category-filter__head:before {
    width: 18px;
    height: 2px;
}

.info-module-revision-category-filter__head:after {
    width: 2px;
    height: 18px;
    top: calc(50% - 9px);
    right: 18px;
    opacity: 1;
    transition: .25s all;
}

.info-module-revision-category-filter.has-flag-mobile .info-module-revision-category-filter__head:after,
.info-module-revision-category-filter.has-flag .info-module-revision-category-filter__head:after {
    opacity: 0;
    transform: rotate(-90deg);
}

.info-module-revision-category-filter .info-module-revision-category-filter__body {
    display: none;
    padding-left: 15px;
}

.info-module-revision-category-filter.has-flag .info-module-revision-category-filter__body {
    display: block;
    padding-top: 5px;
}

.info-module-revision-category-filter li {
    color: var(--color-text);
    padding-left: 15px;
}

.info-module-revision-category-filter li.dropdown {
    padding-left: 0;
}

.info-module-revision-category-filter li>[class*="level-"] {
    display: none;
    padding-left: 15px;
}

.info-module-revision-category-filter li.dropdown:before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    font-size: 12px;
    line-height: 1;
    border-width: 0.4em;
    border-bottom-width: 0;
    border-style: solid;
    border-color: var(--color-text) transparent transparent transparent;
    transition: 0.4s all;
    vertical-align: middle;
}

.info-module-revision-category-filter li.selected {
    color: var(--color-link);
    cursor: pointer;
}

.info-module-revision-category-filter li.dropdown.selected:before {
    border-bottom-width: 0.4em;
    border-top-width: 0;
    border-color: transparent transparent var(--color-link) transparent;
}

.info-module-revision-category-filter li.selected>[class*="level-"] {
    display: block;
}

.info-module-revision-category-filter li>div {
    display: inline;
    padding: 10px 0;
    font-size: 14px;
    line-height: 1.75;
    font-weight: 700;
    color: inherit;
    vertical-align: middle;
}