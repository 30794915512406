.info-module-page-title {
    display: flex; 
    justify-content: flex-start;
    align-items: center;
    min-height: 64px;

    &--space {
        margin: 20px 0;
    }

    @media(--breakpoint-desktop-up) {
        margin: 20px 0;
    }
}