.info-module-calculator {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-areas: 
    'clear clear percentage division'
    'seven eight nine multiplication'
    'four five six minus'
    'one two three plus'
    'zero zero comma equals';
    gap: 5px;
    padding: 10px;
    background-color: var(--color-white);

    @media(--breakpoint-mobile-up) {
        padding: 16px;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border: 0;
        border-radius: 3px;
        cursor: pointer;

        @media(--breakpoint-mobile-up) {
            height: 55px;
        }

        @media(--breakpoint-tablet-up) {
            height: auto;
        }
    }

    &__white-btn, &__clear-btn {
        font-size: 20px;
        line-height: 32px;
        text-transform: uppercase;
    }

    &__white-btn {
        background-color: var(--color-white-md);

    }

    &__gray-btn {
        background-color: var(--color-neutral-mid);
    }

    &__equals-btn {
        background-color: var(--color-light-green);
    }

    &__clear-btn {
        grid-area: clear;
    }

    &__button[data-token-id='percent'] {
        grid-area: percentage;
    }

    &__button[data-token-id='divide'] {
        grid-area: division;
    }

    &__button[data-token-id='seven'] {
        grid-area: seven;
    }

    &__button[data-token-id='eight'] {
        grid-area: eight;
    }

    &__button[data-token-id='nine'] {
        grid-area: nine;
    }

    &__button[data-token-id='multiply'] {
        grid-area: multiplication;
    }

    &__button[data-token-id='four'] {
        grid-area: four;
    }

    &__button[data-token-id='five'] {
        grid-area: five;
    }

    &__button[data-token-id='six'] {
        grid-area: six;
    }

    &__button[data-token-id='minus'] {
        grid-area: minus;
    }

    &__button[data-token-id='one'] {
        grid-area: one;
    }

    &__button[data-token-id='two'] {
        grid-area: two;
    }

    &__button[data-token-id='three'] {
        grid-area: three;
    }

    &__button[data-token-id='plus'] {
        grid-area: plus;
    }

    &__button[data-token-id='zero'] {
        grid-area: zero;
    }

    &__button[data-token-id='comma'] {
        grid-area: comma;
    }

    &__equals-btn {
        grid-area: equals;
    }

    &--hide {
        display: none;
    }

    @media(--breakpoint-tablet-up) {
        position: relative;
        min-width: 371px;
        width: auto;
        border-radius: 3px;
    }
}