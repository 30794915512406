.product-search-response {
    display: none;

    &__text {
        max-width: 250px;
        text-align: center;

        &--darker {
            color: var(--color-black);
        }

        @media (--breakpoint-tablet-up) {
            max-width: 280px;
        }
    }

    &__text-wider {
        max-width: 288px;
        color: var(--color-black);
        text-align: center;

        @media(--breakpoint-tablet-up) {
            max-width: 326px;
        }
    }

    &--show {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 497px;
        gap: 22px;
    }

    @media(--breakpoint-tablet-up) {
        min-height: 834px;
    }
}
