.im-filter-modal {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    width: 75vw;
    height: 100%;
    background-color: var(--color-white);
    z-index: 102;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    pointer-events: none;

    &__head {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        border-bottom: 1px solid var(--color-neutral-mid);
        font-size: 18px;
        line-height: 1.17em;
        font-weight: 700;
        color: var(--color-black);
    }

    &__field {
        display: flex;
        align-items: center;
        gap: 7px;
    }

    &__fields {
        flex: 1;
        padding: 14px 12px;
        text-transform: uppercase;
    }

    &__bottom-btn {
        margin: 0 10px;
        cursor: pointer;
    }

    &__close-btn {
        background-color: transparent;
        border: 0;
        cursor: pointer;
    }

    @media(--breakpoint-tablet-sm-up) {
        top: 50%;
        left: 50%;
        max-width: 416px;
        transform: translate(-50%, -50%);
        opacity: 0;
    }
}

.im-filter-modal--show {
    transform: translateX(0);
    pointer-events: all;
    
    @media(--breakpoint-tablet-sm-up) {     
        transform: translate(-50%, -50%);       
        opacity: 1;
    }
}

.im-filter-modal{
    max-height: 100vh;

    @media (--breakpoint-tablet-sm-up) {
        max-height: 519px;
    }
}

@supports (height: 100svh) {
    .im-filter-modal {
        max-height: 100svh;

        @media (--breakpoint-tablet-sm-up) {
            max-height: 519px;
        }
    }
}

@supports (height: 100dvh) {
    .im-filter-modal {
        max-height: 100dvh;

        @media (--breakpoint-tablet-sm-up) {
            max-height: 519px;
        }
    }
}