.invoice-details {
    &__info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 24px;
    }

    &__info-row {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__field-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 20px;
        padding: 12px;
        background-color: var(--color-details-gray);
    }

    &__imported-file-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;
    }

    &__file-head {
        color: var(--color-black);
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
    }

    &__file-name {
        color: var(--color-black);
        margin-bottom: 4px;
    }

    &__file-info-container {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 1fr 1fr;
        width: fit-content;
    }

    &__file-img {
        grid-row: 1/3;
        margin-right: 12px;
    }
    
    &__product-list-head {
        margin-bottom: 24px;
        color: var(--color-black);
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }
}