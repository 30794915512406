:root {
	--path: ../images;

	--clearfix: {
		content: '';
		display: block;
		clear: both;
	};
	--transparent-bg: {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.7;
		background: #ffffff;
		z-index: -1; 
	}


	--wrapper-width: 1300px;
	--wrapper-extended: 1365px;

	--font-sans-serif:       'Arial', 'Helvetica', sans-serif;
	--font-serif:            'Times New Roman', Times, serif;
	
	/*--font-primary:          'HK Grotesk', sans-serif;*/
	--font-primary:            'Roboto', sans-serif;
	--font-secondary:          'Muller Bold', sans-serif;
	--font-size-primary:     14px;

	--success:                  #a6ce39;
	--error:                    #dc4e41;
	--info:                     #5d76d1;
	--warning:                  #e0a300;

	--color-primary:            #a6ce39; /* green */
	--color-secondary:          #333333; /* black */
	--color-tertiary:           #828ccc; /* light blue */
	--color-neutral-lt:         #f7f7f7;
	--color-neutral-mid:        #e6e6e6;
	--color-neutral-dk:         #acacac;
	--color-white-md:           #f7f7f7;
	--color-gray-dk:			#77797C;
	--color-black:				#000000;
	--color-white:				#ffffff;
	--color-gray-light:			#dfdfdf;
	--color-silver:				#a2a2a2;
	--color-light-green:		#A7CD46;
	--color-light-beige: 		#DFDFDF;
	--color-very-light-green: 		#EBF7E5;
	--color-bright-green: 		#6EBE46;
	--color-light-red: 			#FFF0E5;
	--color-redish: 			#E4843E;
	--color-light-blue: 		#EDF0FA;
	--color-darker-gray: 		#606060;
	--color-details-gray: 		#EEEDED;
	--color-product-light-red: 		#FFE9E8;
	--color-product-light-green: 	#E9FAE1;
	--color-product-green: 		#46A218;
	--color-loght-gray: 		#DCDCDC;
	--color-dark-blue: 			#5D76D2;
	--color-silver:				#a2a2a2;
	--color-purple:             #986CB0;
	--color-light-purple:             #F5EFF9;
	--color-orange: 			#FFC133;
	--color-bright-yellow: 		#F6FF33;

	--color-titles:             var(--color-secondary);
	--color-text:               #6c6c6c;
	--color-link:               var(--color-primary);

	--color-category-1:            #cc5b54; /* red */
	--color-category-2:            #5d76d1; /* blue */
	--color-category-3:            #6ebe47; /* green */
	--color-category-4:            #e4843e; /* orange */
	--color-category-5:            #996ab2; /* purple */
	--color-category-6:            #a47f62; /* brown */
	--color-category-7:            #ed1847; /* pink/purple */ 
	--color-category-8:            #f3809c; /* pink */
	--color-category-9:            #cd7b4b; /* light-orange */
	--color-category-10:           #a04c35; /* light brown*/
	--color-category-11:           #e8b92e; /* yellow */
	--color-category-12:           #64bde1; /*light blue */
	--color-promo:                 var(--color-category-7);
	--color-light-green:		#A7CD46;

	--col-1:                       8.33333333%;
	--col-2:                       16.66666667%;
	--col-3:                       25%;
	--col-4:                       33.33333333%;
	--col-5:                       41.66666667%;
	--col-6:                       50%;
	--col-7:                       58.33333333%;
	--col-8:                       66.66666667%;
	--col-9:                       75%;
	--col-10:                      83.33333333%;
	--col-11:                      91.66666667%;
	--col-12:                      100%;
	--shadow-default: 0 1px 1px rgba(0, 0, 0, .3);
	--shadow-blured: 0 1px 4px rgba(0, 0, 0, .3);
	--shadow-fields: inset 3px 0 2px rgba(32, 32, 32, .07);

	--swiper-theme-color: #007aff;
	--swiper-navigation-size: 44px;
	--swiper-preloader-color: #000;
	--swiper-preloader-color: #fff;
	--swiper-pagination-color: #000;
	@custom-media --breakpoint-desktop-lg (max-width: 1300px);
	@custom-media --breakpoint-desktop (max-width: 1200px);
	@custom-media --breakpoint-tablet (max-width: 991px);
	@custom-media --breakpoint-tablet-sm (max-width: 767px);
	@custom-media --breakpoint-mobile (max-width: 479px);

	@custom-media --breakpoint-desktop-lg-up (min-width: 1300px);
	@custom-media --breakpoint-desktop-up (min-width: 1200px);
	@custom-media --breakpoint-tablet-up (min-width: 991px);
	@custom-media --breakpoint-tablet-sm-up (min-width: 767px);
	@custom-media --breakpoint-mobile-up (min-width: 479px);
}