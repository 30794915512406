.confirm-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--color-white);
    border-radius: 3px;
    transform: translate(-50%, -50%) scale(0.95);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease-out, transform 0.15s ease-out;

    h3 {
        text-align: center;
        font-size: 20px;
    }

    &__btn-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &.active {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        pointer-events: auto;
    }
}
