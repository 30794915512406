.custom-dropdown {
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    display: none;
    width: 100%;
    max-height: 150px;
    overflow: auto;
    z-index: 1;
    border: 1px solid;
    background-color: var(--color-white);

    &__option {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 25px;
        padding-left: 10px;

        &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }

    &--active {
        position: absolute;
        display: flex;
        flex-direction: column;
    }

    @media(--breakpoint-desktop-up) {
        max-height: 200px;
    }
}