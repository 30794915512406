/* ------------------------------------------------------------ *\
	#header
\* ------------------------------------------------------------ */
.header { width: 100%; background: #f7f8f8; }
.header .logo { max-width: 215px; }
.header .logo img { width: 100%; }
.header-top .btn-login,
.header .nav-phone,
.header .btn-stores { display: inline-block; position: relative; height: auto; width: auto; font-size: 0; line-height: 0; padding: 0 10px; font-family: var(--font-secondary); color:#000; box-sizing: border-box;  background: none; text-decoration: none; }
.header .btn-stores { border-left: 1px solid #bebebe; }
.header-top .btn-login { width: auto; height: auto;  }
.header-top .btn-login span,
.header .nav-phone span,
.header .btn-stores span { font-size: 16px; line-height: 1; vertical-align: middle; }
.header-top .btn-login .icon-svg,
.header .nav-phone .icon-svg,
.header .btn-stores .icon-svg { position: relative; top: auto; left: auto; height: 20px; width: 20px; margin-right: 5px; fill: #333333; vertical-align: middle; transform: none; }
.header-top { padding: 10px 0; border-bottom: 1px solid var(--color-neutral-mid); background: #ffffff; }
.header-top .cols { font-size: 0; line-height: 0; }
.header-top .cols .col { float: none; display: inline-block; vertical-align: middle; }
.header-top .cols .col:last-child { text-align: right; }
.header-top .btn-login { display: none; }
.header-top .wrapper:after { @apply --clearfix; }
.header-top .contact-us { float: left; padding: 11px 0; font-size: 13px; line-height: 18px; }
.header-top .contact-us a { color: var(--color-secondary); font-weight: bold; }
.header-top .nav-secondary { float: right; }
.header-bottom { background: #ffffff; }
.header-bottom .wrapper { position: relative; }
.header-bottom .cols .col:last-child { text-align: right; }
.header-bottom .wrapper:after { @apply --clearfix; }
.header-bottom .nav-search { margin: 0 auto; padding: 15px 0; max-width: 625px; overflow: hidden; }
.header-bottom .nav-search .search-input { border-radius: 4px; border: 1px solid #dcdcdc; height: 35px; }
.header-bottom .nav-search .btn-search { position: absolute; top: 0; right: 0; height: 35px; width: 56px; background: var(--color-primary); -webkit-appearance: none; -moz-appearance: none; -ms-appearance: none; appearance: none; border-radius: 0 4px 4px 0; }
.header-bottom .nav-search .icon-search { width: 20px; height: 20px; fill: #ffffff; }
.header-bottom .btn-login { width: auto; height: auto; padding: 20px 0; border: none; }
.header-bottom .btn-login:hover .icon-login { fill: var(--color-primary); }
.header-bottom .btn-login .icon-login { display: inline-block; position: relative; top: 0; left: 0; width: 18px; transform: none; height: 21px; vertical-align: middle; padding: 0 5px 0 20px; border-left: 1px solid #bebebe; }
.header-bottom .btn-login span { display: inline-block; text-transform: uppercase; font-size: 16px; line-height: 1.45; font-family: var(--font-secondary); vertical-align: middle; color: #000000; }
.header-bottom .bottom .nav-search { display: none; }
.header-bottom .bottom .nav-primary { float: unset; text-align: center; }
.header-bottom .bottom { opacity: 1; transition: all 0.3s; }
