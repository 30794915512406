.navigation-bar {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 65px;
    padding: 7px 0 17px 0;
    background-color: var(--color-white-md);
    z-index: 1;
    transform: translateY(0);

    &__button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        flex: 1;
        gap: 4px;
        font-size: 9px;
        color: var(--color-gray-dk);
        text-decoration: none;

        @media (--breakpoint-tablet-sm-up) {
            flex-direction: row;
            white-space: nowrap;
            font-size: 16px;
        }
    }

    &__button-img {
        min-height: 21px;
    }

    &__button:focus {
        text-decoration: none;
    }

    &__selected-button-img {
        display: none;
        min-height: 21px;
    } 

    @media (--breakpoint-tablet-up) {
        position: relative;
        gap: 40px;
        padding: 0;
        height: auto;
        background: transparent;
    }
}

.navigation-bar__button--selected {
    text-decoration: none;
    color: var(--color-black);
}

.navigation-bar__button--selected .navigation-bar__selected-button-img {
    display: flex;
}

.navigation-bar__button--selected .navigation-bar__button-img {
    display: none;
}
