.custom-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .custom-checkbox input {
    position: absolute;
    top: 0;
    left: 0;
  }

  .custom-checkbox__checkmark {
    position: relative;
    display: block;
    height: 20px;
    width: 20px;
    background-color: var(--color-white);
    border: 1px solid;
    border-color: var(--color-neutral-mid);
  }

  .custom-checkbox input:checked ~ .custom-checkbox__checkmark {
    background-color: var(--color-primary);
  }

  .custom-checkbox__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .custom-checkbox input:checked ~ .custom-checkbox__checkmark:after {
    display: block;
  }

  .custom-checkbox .custom-checkbox__checkmark:after {
    left: 6.5px;
    top: 4.5px;
    width: 5px;
    height: 10px;
    border: solid var(--color-white);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }