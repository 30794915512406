.low-availability {
	position: relative;
    padding: 20px 0 80px;
    background-color: var(--color-white-md);
    border-bottom: 1px solid var(--redesign-color-border); 	

    .wrapper {
        position: relative;
    }
}

.low-availability__title {
	margin-bottom: 20px;
	font-size: 24px;
	font-weight: 600;
	color: var(--redesign-color-black-md);
}

.low-availability__container {
	display: flex;
    flex-direction: column;
}

.low-availability__items {
	display: flex;
	flex-direction: column;
}

.low-availability__item {
    position: relative;
	display: flex;
    flex-direction: column;
	align-items: center;
    gap: 10px;
	justify-content: space-between;
    margin-bottom: 20px;	
	color: var(--redesign-color-black-md);
	background-color: #fff;
	font-size: 16px;

    @media(--breakpoint-desktop-up) {
        flex-direction: row;
        padding-bottom: 22px;
    }
}

.low-availability-item__title {
    font-size: 17px;
}

.low-availability__item-container {
    flex: 1;
}

.low-availability__item-wrapper {
	display: flex;
	align-items: center;
    padding-top: 5px;
	gap: 10px;
	width : 100%;
}

.low-availability__item-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.low-availability-item__content-button {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    border-radius: 3px;
    cursor: pointer;
    
    &:hover {
        background-color: var(--color-white-md);
    }

    @media(--breakpoint-desktop-up) {
        position: absolute;
        top: 10px;
        right: 10px;
        width: auto;
        padding: 10px;
    }
}

.low-availability__filter {
    @media(--breakpoint-mobile-up) {
        max-width: 300px;
    }
}

.low-availability__summary { 
	position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 0;
    color: #fff;
	font-size: 14px; 
	line-height: 1.2;

    @media(--breakpoint-mobile-up) {
        flex-direction: row;
    }
 }

 .low-availability__summary-button.disabled{
    background-color: var(--color-neutral-dk);
    pointer-events: none;
    cursor: not-allowed;
 }

.low-availability__summary-button.btn.btn-primary {
    @media(--breakpoint-mobile-up) {
        max-width: 300px;
    }
}

.low-availability__scroll-btn.btn-scroll-top { 
    position: fixed;
    bottom: 75px;
    right: 20px;
    background-color: var(--color-primary);

    &::after { 
        color: white; 
    }

    &:hover { 
        background-color: #333; 
    } 
}

.low-availability__message {
    padding-top: 20px;
    text-align: center;
    font-size: 20px;
}