.info-module-product-info-table {
    --product-red: #CC5B54;
    --product-light-red: #FFE9E8;
    --product-light-green: #E9FAE1;
    --product-green: #46A218;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--color-neutral-mid);

    &__info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px;
    }

    &__name {
        max-width: 495px;
        word-wrap: break-word;
        color: var(--color-black);
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        @media(--breakpoint-desktop-up) {
            max-width: 350px;
        }
    }

    &__identifier {
        word-wrap: break-word;
    }

    &__identifier-number {
        color: var(--color-black);
    }

    &__quantity-table {
        border-top: 1px solid var(--color-neutral-mid);
    }

    &__table-row {
        display: grid;
        grid-template-columns: 0.5fr 1fr 1fr 1fr;

        &--not-scanned {
            display: flex;
            justify-content: center;

            .info-module-delivery-product__table-item {
                color: var(--color-text);
            }
        }
    }

    &__table-row:not(:last-child) {
        border-bottom: 1px solid var(--color-neutral-mid);
    }

    &__table-item {
        display: flex;
        justify-content: center;
        padding: 6px 0;
        color: var(--color-black);
        border-bottom: 0;

        &--invoice-count-higher-than-inventorization-count {
            color: var(--product-red);
            background-color: var(--product-light-red);
        }

        &--inventorization-count-higher-than-invoice-count {
            color: var(--product-green);
            background-color: var(--product-light-green);
        }

        &--not-scanned {
            width: 100%;
            padding: 6px 5px;
        }
    }

    &__table-item:not(:last-child) {
        border-right: 1px solid var(--color-neutral-mid);
    }
}
