.info-module-import-invoice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 24px;

        @media(--breakpoint-tablet-sm-up) {
            width: 500px;
        }

        @media(--breakpoint-tablet-up) {
            max-width: none;
            width: auto;
        }
    }

    @media(--breakpoint-tablet-up) {
        display: block;
    }
}