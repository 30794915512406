.info-module-product-list {
    display: none;
    margin-bottom: 149px;

    &__head {
        padding-bottom: 24px;
        font-family: var(--font-primary);
        font-size: 18px;
        line-height: 21.1px;
        font-weight: 700;
        color: var(--color-black);
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__chunk {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 20px;
    }

    &--show {
        display: block;
    }
}